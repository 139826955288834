import { ChangeDetectorRef, inject, Injectable } from '@angular/core';
import { ManageTableMenuAction } from '../../manage-table/types/manage-table-menu-action.type';
import { MenuPanelItem } from '../../header-navigation/components/menu-panel/menu-panel.type';
import { Observable, of, Subject } from 'rxjs';
import { BudgetObjectService } from '@shared/services/budget-object.service';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { RecordInteractionService } from '@manage-ceg/services/record-interaction.service';
import { catchError, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ManageCegTableRow, ManageCegTableSelectionState, ManageCegViewMode } from '@manage-ceg/types/manage-ceg-page.types';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { Configuration } from 'app/app.constants';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { UserManager } from '../../user/services/user-manager.service';
import { UtilityService } from '../../shared/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { CompanyDataService } from '../../shared/services/company-data.service';
import { EditCustomFieldsModalComponent } from '../../shared/modals/edit-custom-fields-modal/edit-custom-fields-modal.component';
import { CustomFieldsAdminService } from '../../account/custom-fields/custom-fields.service';
import { CustomFieldStatusService } from '../../shared/services/custom-field-bulk-update.service';

@Injectable()
export class ManageCegTableActionsMenuService {
  private readonly manageCegPageService = inject(ManageCegPageService);
  private readonly recordInteractionService = inject(RecordInteractionService);
  private readonly dataService = inject(ManageCegTableDataService);
  private readonly viewModeService = inject(ManageCegPageModeService);
  private readonly configuration = inject(Configuration);
  private readonly userManager = inject(UserManager);
  private readonly utilityService = inject(UtilityService);
  private readonly dialog = inject(MatDialog);
  private readonly companyDataService = inject(CompanyDataService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly cfAdminService = inject(CustomFieldsAdminService);
  private readonly cfStatusService = inject(CustomFieldStatusService);
  private isCustomFieldEnabled: boolean = false;
  private isReadOnlyUser: boolean = false;

  private actionsMap: Record<string, MenuPanelItem> = {};
  private readonly destroy$ = new Subject<void>();
  private budgetObjectTypes: BudgetObjectType[] = [];
  private budgetObjectTypeItems: MenuPanelItem[] = [];
  private externalIntegrationObjectTypes: number[] = [];
  private statusItems: MenuPanelItem[] = [{
    label: this.configuration.statusNames.planned,
    value: this.configuration.statusNames.planned,
    action: () => this.manageCegPageService.changeStatus(CEGStatus.PLANNED),
    hidden: false
  }, {
    label: this.configuration.statusNames.committed,
    value: this.configuration.statusNames.committed,
    action: () => this.manageCegPageService.changeStatus(CEGStatus.COMMITTED),
    hidden: false
  }];

  constructor() {
    this.recordInteractionService.selectionChanged$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(selection => this.handleSelectionChange(selection));

      this.hasCustomFieldEnabled().subscribe(enabled => {
        this.isCustomFieldEnabled = enabled;
        this.cdr.detectChanges();
      });

      this.userManager.currentCompanyUser$
      .pipe(
        takeUntil(this.destroy$),
        filter(user => !!user),
        take(1)
      )
      .subscribe(user => {
        this.isReadOnlyUser = !user.is_admin && user.permissions?.every(p => p.read && !p.read_write);
        this.cdr.detectChanges();
      });
  }

  private handleSelectionChange(selection: ManageCegTableSelectionState): void {
    this.validateActions(selection);
  }

  private defineSelectionPredicates(selection: ManageCegTableSelectionState) {
    const { goals, campaigns, expGroups, segments, segmentGroups } = selection;
    let anyCampaignClosed = false;
    let anyCampaignOpen = false;
    let anyExpGroupClosed = false;
    let anyExpGroupOpen = false;
    let anyParentCampaignSelected = false;
    let anyExternalIntegrationObjectSelected = false;
    let isSegmentlessObject = false;
    let anyCommittedAndChild = false;

    for (const campaignId of campaigns) {
      const campaignRecord = this.dataService.getRecordByIdAndType(ManageTableRowType.Campaign, campaignId);
      if (campaignRecord && !anyParentCampaignSelected) {
        anyParentCampaignSelected = campaignRecord.children.some(child => child.type === ManageTableRowType.Campaign);
      }
      if (campaignRecord?.isClosed) {
        anyCampaignClosed = true;
      } else {
        anyCampaignOpen = true;
      }
      if (!anyExternalIntegrationObjectSelected && this.externalIntegrationObjectTypes.includes(campaignRecord?.objectId)) {
        anyExternalIntegrationObjectSelected = true;
      }
      if (ManageCegTableDataService.isSegmentlessObject(campaignRecord)) {
        isSegmentlessObject = true;
      }
      if (!anyCommittedAndChild) {
        anyCommittedAndChild = this.isCommittedParentSelected(campaignRecord, campaigns);
      }
    }
    for (const expGroupId of expGroups) {
      const expGroupRecord = this.dataService.getRecordByIdAndType(ManageTableRowType.ExpenseGroup, expGroupId);

      if (expGroupRecord?.isClosed) {
        anyExpGroupClosed = true;
      } else {
        anyExpGroupOpen = true;
      }
      if (!anyExternalIntegrationObjectSelected && this.externalIntegrationObjectTypes.includes(expGroupRecord.objectId)) {
        anyExternalIntegrationObjectSelected = true;
      }
      if (!anyCommittedAndChild) {
        anyCommittedAndChild = this.isCommittedParentSelected(expGroupRecord, campaigns);
      }
    }

    const selectedCount = goals.size + campaigns.size + expGroups.size;
    const anyBudgetObjectSelected = selectedCount > 0;
    const noSegmentedObjectSelected = (campaigns.size + expGroups.size) === 0;
    const goalsOrSegmentsSelected = (goals.size + segments.size + segmentGroups.size) > 0;
    const anyClosedObjectSelected = anyCampaignClosed || anyExpGroupClosed;
    const anyOpenObjectSelected = anyCampaignOpen || anyExpGroupOpen;
    const nothingOrClosedObjectsSelected = !anyBudgetObjectSelected || anyClosedObjectSelected;
    const singleOpenObjectSelected = selectedCount === 1 && !anyClosedObjectSelected;
    const allSelectedAreClosed = anyBudgetObjectSelected && !anyOpenObjectSelected;
    const allSelectedAreOpen = anyBudgetObjectSelected && !anyClosedObjectSelected;
    const campaignsAndGroupsSelected = campaigns.size > 0 && expGroups.size > 0;
    const onlyCampaignsSelected = campaigns.size > 0 && expGroups.size === 0;
    const onlyExpGroupsSelected = expGroups.size > 0 && campaigns.size === 0;

    return {
      anyBudgetObjectSelected,
      nothingOrClosedObjectsSelected,
      singleOpenObjectSelected,
      anyClosedObjectSelected,
      anyOpenObjectSelected,
      allSelectedAreClosed,
      allSelectedAreOpen,
      noSegmentedObjectSelected,
      anyParentCampaignSelected,
      goalsOrSegmentsSelected,
      onlyCampaignsSelected,
      onlyExpGroupsSelected,
      campaignsAndGroupsSelected,
      anyExternalIntegrationObjectSelected,
      isSegmentlessObject,
      anyCommittedAndChild
    };
  }

  private validateActions(selection: ManageCegTableSelectionState) {
    const {
      anyBudgetObjectSelected,
      anyClosedObjectSelected,
      anyOpenObjectSelected,
      nothingOrClosedObjectsSelected,
      singleOpenObjectSelected,
      allSelectedAreClosed,
      allSelectedAreOpen,
      noSegmentedObjectSelected,
      anyParentCampaignSelected,
      goalsOrSegmentsSelected,
      onlyCampaignsSelected,
      anyExternalIntegrationObjectSelected,
      isSegmentlessObject,
      anyCommittedAndChild
    } = this.defineSelectionPredicates(selection);
    const currentMode = this.viewModeService.viewMode;

    this.actionsMap[ManageTableMenuAction.Export].disabled = anyBudgetObjectSelected;
    this.actionsMap[ManageTableMenuAction.Delete].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.AddTags].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.RemoveTags].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.Duplicate].disabled = !singleOpenObjectSelected;
    this.actionsMap[ManageTableMenuAction.ChangeStatus].disabled = anyClosedObjectSelected || noSegmentedObjectSelected || anyCommittedAndChild;

    this.actionsMap[ManageTableMenuAction.MoveTo].disabled = nothingOrClosedObjectsSelected
      || noSegmentedObjectSelected
      || (anyParentCampaignSelected && currentMode === ManageCegViewMode.Campaigns)
      || goalsOrSegmentsSelected
      || (currentMode !== ManageCegViewMode.Goals && isSegmentlessObject);

    this.actionsMap[ManageTableMenuAction.Close].disabled = nothingOrClosedObjectsSelected;
    this.actionsMap[ManageTableMenuAction.Close].hidden = allSelectedAreClosed;

    this.actionsMap[ManageTableMenuAction.Reopen].disabled = !allSelectedAreClosed;
    this.actionsMap[ManageTableMenuAction.Reopen].hidden = !anyBudgetObjectSelected || allSelectedAreOpen ||
      (anyClosedObjectSelected && anyOpenObjectSelected);

    this.actionsMap[ManageTableMenuAction.ChangeType].disabled =
      anyExternalIntegrationObjectSelected || nothingOrClosedObjectsSelected || noSegmentedObjectSelected;
    this.actionsMap[ManageTableMenuAction.ChangeType].children = this.budgetObjectTypeItems;
    this.actionsMap[ManageTableMenuAction.AddMetrics].disabled = !onlyCampaignsSelected || anyClosedObjectSelected;
    this.actionsMap[ManageTableMenuAction.CustomField].disabled = goalsOrSegmentsSelected || nothingOrClosedObjectsSelected || this.isReadOnlyUser;
  }

  private initActionsMap() {
    this.actionsMap = {
      [ManageTableMenuAction.Duplicate]: {
        label: 'Duplicate',
        faIcon: ['fas', 'copy'],
        action: () => this.manageCegPageService.duplicateSelectedItems(),
        disabled: true
      },
      [ManageTableMenuAction.MoveTo]: {
        label: 'Move to',
        faIcon: ['fas', 'folder-open'],
        action: () => this.manageCegPageService.openHierarchySelection(),
        disabled: true
      },
      [ManageTableMenuAction.Close]: {
        label: 'Close',
        faIcon: ['fas', 'hourglass-end'],
        action: () => this.manageCegPageService.closeItems(),
        disabled: true
      },
      [ManageTableMenuAction.Reopen]: {
        label: 'Reopen',
        faIcon: ['fas', 'hourglass-start'],
        action: () => this.manageCegPageService.reopenItems(),
        disabled: true,
        hidden: true
      },
      [ManageTableMenuAction.ChangeType]: {
        label: 'Change Type',
        faIcon: ['fas', 'copy'],
        action: null,
        isDivider: false,
        disabled: true,
        children: []
      },
      [ManageTableMenuAction.AddTags]: {
        label: 'Add Tags',
        faIcon: ['fas', 'tags'],
        action: () => this.manageCegPageService.addTagsToSelectedItems(),
        isDivider: false,
        disabled: true,
      },
      [ManageTableMenuAction.RemoveTags]: {
        label: 'Remove Tags',
        faIcon: ['fas', 'tags'],
        action: () => this.manageCegPageService.removeTagsFromSelectedItems(),
        isDivider: false,
        disabled: true,
      },
      [ManageTableMenuAction.AddMetrics]: {
        label: 'Add metrics',
        faIcon: ['fas', 'chart-line'],
        action: () => this.manageCegPageService.openMetricList(),
        isDivider: false,
        disabled: true
      },
      [ManageTableMenuAction.ChangeStatus]: {
        label: 'Change Status',
        faIcon: ['fas', 'wave-pulse'],
        isDivider: false,
        disabled: true,
        children: this.statusItems
      },
      [ManageTableMenuAction.Delete]: {
        label: 'Delete',
        faIcon: ['fas', 'trash'],
        action: () => this.manageCegPageService.deleteItems(),
        isDivider: true,
        disabled: true,
        customCssClass: 'item-danger'
      },
      [ManageTableMenuAction.Export]: {
        label: 'Export',
        faIcon: ['fas', 'cloud-arrow-down'],
        action: () => this.manageCegPageService.exportData(),
        isDivider: true,
        disabled: false
      },
    };

    if(this.hasCustomFieldEnabled()){
      this.actionsMap[ManageTableMenuAction.CustomField] = {
        label: 'Edit Custom Fields',
        faIcon: ['fas', 'list-dropdown'],
        action: () => this.updateCustomField().pipe(
          catchError(error => {
            this.utilityService.handleError(error);
            return of(null);
          })
        ).subscribe(),
        isDivider: false,
        disabled: true
      };
    }
  }
  
  private hasCustomFieldEnabled(): Observable<boolean> {
    return this.cfStatusService.fetchCustomFieldStatus().pipe(
      map(res => res?.isCustomFieldEnabled ?? false)
    );
  }
  
  private updateCustomField(): Observable<any> {
    const selectedCampaignItems = [...this.recordInteractionService?.selectionState.campaigns];
    const selectedExpGroupItems = [...this.recordInteractionService?.selectionState.expGroups];
  
    return this.cfAdminService.getCFList({ company: this.companyDataService.selectedCompanySnapshot.id }).pipe(
      take(1),
      tap(res => {  
        const hasCampaignSelection = selectedCampaignItems.length > 0;
        const hasProgramSelection = selectedExpGroupItems.length > 0;
  
        const hasCFForCampaign = res.some(cf => cf.for_campaign);
        const hasCFForProgram = res.some(cf => cf.for_program);
        const hasSingleCFForBoth = res.some(cf => cf.for_campaign && cf.for_program);
  
        if (hasCampaignSelection && hasProgramSelection) {
          if (hasSingleCFForBoth) {
            this.openEditCustomFieldsModal(true, true);
          } else {
            this.openNoCustomFieldsModal();
          }
        } else if (hasCampaignSelection) {
          if (hasCFForCampaign) {
            this.openEditCustomFieldsModal(true, false);
          } else {
            this.openNoCustomFieldsModal();
          }
        } else if (hasProgramSelection) {
          if (hasCFForProgram) {
            this.openEditCustomFieldsModal(false, true);
          } else {
            this.openNoCustomFieldsModal();
          }
        } else {
          this.openNoCustomFieldsModal();
        }
      })
    );
  }      

  private openEditCustomFieldsModal(isCampaign, isProgram): void {
    let selectedItems = {
      campaign: [...this.recordInteractionService?.selectionState.campaigns],
      program: [...this.recordInteractionService?.selectionState.expGroups]
    };
    const dialogRef = this.dialog.open(EditCustomFieldsModalComponent, {
      width: '570px',
      data: {
        company: this.companyDataService.selectedCompanySnapshot.id,
        isExpense: false,
        isCampaign: isCampaign,
        isProgram: isProgram,
        content: `The selected Campaign(s), Expense Group(s) will be updated with the above custom field values. This action cannot be undone.`,
        selectedItems: selectedItems,
      }
    });
  
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result === true) {
        this.utilityService.showToast({ Title: '', Message: 'Custom field edited for selected objects.', Type: 'success' });
      } else if (result === false) {
        this.utilityService.showToast({ Title: '', Message: 'Unable to edit Custom field.', Type: 'error' });
      }
    });
  }

  private openNoCustomFieldsModal(): void {
    this.userManager.currentCompanyUser$.pipe(take(1)).subscribe(companyUser => {
      const isAdmin = companyUser?.is_admin ?? false;
      this.cfStatusService.openNoCustomFieldsFoundModal(isAdmin);
    });
  }  

  private generateChangeTypeMenuItems() {
    this.externalIntegrationObjectTypes = BudgetObjectService.getExternalIntegrationTypeIds(this.budgetObjectTypes);

    const actionHandler = (target: MenuPanelItem) => this.manageCegPageService.changeSelectedItemsObjectType(
      Number(target.value),
      target.label
    );
    const mapTypeToMenuItem = (objectType: BudgetObjectType) => ({
      label: objectType.name,
      action: actionHandler,
      value: objectType.id,
      faIcon: null
    });

    this.budgetObjectTypeItems = this.budgetObjectTypes
      .filter(objectType => !this.externalIntegrationObjectTypes.includes(objectType.id))
      .map(mapTypeToMenuItem);
  }

  public setObjectTypes(
    budgetObjectTypes: BudgetObjectType[]
  ) {
    this.budgetObjectTypes = [...budgetObjectTypes];
    this.generateChangeTypeMenuItems();
  }

  public generateMenu(): MenuPanelItem[] {
    this.initActionsMap();

    return [
      this.actionsMap[ManageTableMenuAction.Duplicate],
      this.actionsMap[ManageTableMenuAction.MoveTo],
      this.actionsMap[ManageTableMenuAction.Close],
      this.actionsMap[ManageTableMenuAction.Reopen],
      this.actionsMap[ManageTableMenuAction.ChangeType],
      this.actionsMap[ManageTableMenuAction.AddTags],
      this.actionsMap[ManageTableMenuAction.RemoveTags],
      this.actionsMap[ManageTableMenuAction.AddMetrics],
      this.actionsMap[ManageTableMenuAction.ChangeStatus],
      this.actionsMap[ManageTableMenuAction.CustomField],
      this.actionsMap[ManageTableMenuAction.Delete],
      this.actionsMap[ManageTableMenuAction.Export],
    ];
  }

  private isCommittedParentSelected(record: ManageCegTableRow, selectedCampaigns: Set<number>): boolean {
    if (record.status !== CEGStatus.COMMITTED || !record.parentId) {
      return false;
    }
    const [parentType, parentId] = record.parentId.split('_');
    const parentRecord = this.dataService.getRecordByIdAndType(ManageTableRowType.Campaign, parentId);
    return parentType === this.configuration.OBJECT_TYPES.campaign.toLowerCase()
      && !selectedCampaigns.has(+parentId)
      && parentRecord?.status === CEGStatus.COMMITTED;

  }
}
