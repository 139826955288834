// Please include the strings which are used by app components
// GLOBAL STRINGS are NOT TO  BE INCLUDED HERE
// DON'T INCLUDE STRINGS WHICH ARE USED BY MULTIPLE/SINGLE COMPONENTS
export const LoginStrings: any = {
  dialogs: {
    inactiveCompanyUserLogin: {
      title: 'Your Account Has Been Disabled',
      content: 'Your organization is currently marked as inactive. Contact your administrator or Planful Support to resolve this.'
    }
  }
}
