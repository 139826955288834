import { Injectable } from '@angular/core';
import {
  BudgetObjectsData,
  BudgetSegmentsData,
  ExpensePageBudgetData,
  ExpensePageCompanyData,
  HierarchyViewMode,
  SidebarHierarchyOption,
  SidebarObjectTypes
} from '../types/expense-page.type';
import { GLCode, Vendor } from 'app/shared/services/company-data.service';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ObjectMode } from 'app/shared/enums/object-mode.enum';
import { Configuration } from 'app/app.constants';
import {
  HierarchyBuilder,
  HierarchyCampaignItem,
  HierarchyExpenseGroupItem,
  HierarchyGoalItem,
  HierarchySegmentGroupItem,
  HierarchySegmentItem,
  OptionItem
} from './hierarchy.builder';
import { BudgetObjectSourceLabels } from '../../budget-object-details/types/budget-object-details-state.interface';

interface DefaultListItem {
  id: number | string;
  name: string;
  [key: string]: any;
}

@Injectable()
export class SpendingSidebarOptionsProviderService {
  private currentSidebarSelection: SidebarHierarchyOption[];

  private static getBaseOption(item: DefaultListItem, objectType: SidebarObjectTypes): SidebarHierarchyOption {
    return {
      id: item.id,
      title: item.name,
      objectType,
      visible: true,
      level: 1,
      description: item.description,
    };
  }

  private static getEmptyOption(objectType: SidebarObjectTypes): SidebarHierarchyOption {
    const DefaultOptionTitle = {
      [SidebarObjectTypes.Goal]: 'Goal',
      [SidebarObjectTypes.Campaign]: 'Campaign',
      [SidebarObjectTypes.GlCode]: 'GL Code',
      [SidebarObjectTypes.Vendor]: 'Vendor',
    };

    return {
      id: 0,
      title: 'no ' + DefaultOptionTitle[objectType],
      objectType,
      visible: true,
      isDefault: true,
      level: 1
    };
  }

  constructor(
    private readonly configuration: Configuration,
    private readonly hierarchyBuilder: HierarchyBuilder,
  ) {}

  getViewOptionsForMode(
    hierarchyModeValue: HierarchyViewMode,
    companyData: ExpensePageCompanyData,
    budgetData: ExpensePageBudgetData,
    budgetObjects: BudgetObjectsData,
    currentSidebarSelection: Record<number | string, SidebarHierarchyOption>
  ): SidebarHierarchyOption[] {
    this.currentSidebarSelection = Object.values(currentSidebarSelection);
    const optionsProviderByMode = {
      [HierarchyViewMode.Segment]: () =>
        this.getSegmentOptions(budgetData?.budgetHierarchyData.segmentsData, budgetObjects),
      [HierarchyViewMode.Goal]: () => this.getGoalOptions(budgetObjects),
      [HierarchyViewMode.Campaign]: () => this.getCampaignOptions(budgetObjects),
      [HierarchyViewMode.GlCode]: () => this.getGLCodeOptions(companyData.glCodes),
      [HierarchyViewMode.Source]: () => this.getSourceOptions(),
      [HierarchyViewMode.Status]: () => this.getStatusOptions(),
      [HierarchyViewMode.Timeframe]: () => this.getTimeframeOptions(budgetData.budgetTimeframes),
      [HierarchyViewMode.Vendor]: () => this.getVendorOptions(companyData.vendors)
    };

    const optionsProvider = optionsProviderByMode[hierarchyModeValue];
    if (!optionsProvider) {
      console.error(`Missing optionsProviderByMode for "${hierarchyModeValue}"`);
      return [];
    }
    return optionsProvider();
  }

  private getSelectedOptionIds(objectType: SidebarObjectTypes): (number | string)[] {
    return this.currentSidebarSelection
      .filter(item => item.objectType === objectType)
      .map(item => item.id);
  }

  private getGLCodeOptions(glCodes: GLCode[]): SidebarHierarchyOption[] {
    return [
      SpendingSidebarOptionsProviderService.getEmptyOption(SidebarObjectTypes.GlCode),
      ...glCodes.map(item => SpendingSidebarOptionsProviderService.getBaseOption(item, SidebarObjectTypes.GlCode)
    )];
  }

  private getVendorOptions(vendors: Vendor[]): SidebarHierarchyOption[] {
    return [
      SpendingSidebarOptionsProviderService.getEmptyOption(SidebarObjectTypes.Vendor),
      ...vendors.map(item => SpendingSidebarOptionsProviderService.getBaseOption(item, SidebarObjectTypes.Vendor)
      )
    ];
  }

  private getTimeframeOptions(timeframes: BudgetTimeframe[]): SidebarHierarchyOption[] {
    return  timeframes.map(item => ({
      ...SpendingSidebarOptionsProviderService.getBaseOption(item, SidebarObjectTypes.Timeframe),
      locked: item.locked,
    }));
  }

  private getSourceOptions(): SidebarHierarchyOption[] {
    const allSources: DefaultListItem[] = Object.entries(BudgetObjectSourceLabels)
      .map(([key, value]) => ({ id: key, name: value }));

    return allSources.map(
      item => SpendingSidebarOptionsProviderService.getBaseOption(item, SidebarObjectTypes.Source)
    );
  }

  private getStatusOptions(): SidebarHierarchyOption[] {
    const allStatuses: DefaultListItem[] = this.configuration.modes.map(status => ({
      id: status,
      name: status,
    }));

   return allStatuses.map(
      item => SpendingSidebarOptionsProviderService.getBaseOption(item, SidebarObjectTypes.Status)
    );
  }

  private getSegmentOptions(segmentsData: BudgetSegmentsData, budgetObjects: BudgetObjectsData): SidebarHierarchyOption[] {
    const selectedSegmentIds = this.getSelectedOptionIds(SidebarObjectTypes.Segment);
    const selectedSegmentGroups = this.currentSidebarSelection.filter(item => item.objectType === SidebarObjectTypes.SegmentGroup);
    if (selectedSegmentGroups.length) {
      selectedSegmentGroups.forEach(group => {
        selectedSegmentIds.push(...group.groupSegmentIds);
      });
    }

    const segmentHierarchyMap = this.hierarchyBuilder.buildSegmentHierarchy(segmentsData, budgetObjects);

    const segmentGroupOptions =
      Object.values(segmentHierarchyMap.segmentGroups).sort(this.compareOptions).flatMap(
        segmentGroup => this.getHierarchyOptionsForSegmentGroup(segmentGroup)
      );

    const segmentOptionsData =
      Object.values(segmentHierarchyMap.segments).sort(this.compareOptions).flatMap(
        segment => this.getHierarchyOptionsForSegment(1, segment)
      );

      const segmentOptions = segmentOptionsData.filter(el => el);

    // Move default to beginning
    const defaultSegmentIndex = (segmentOptions || []).findIndex(option => option.isDefault);
    if (defaultSegmentIndex > -1) {
      const childrenNumber = segmentOptions.filter(child => child?.segmentId === segmentOptions[defaultSegmentIndex].id).length;
      const defaultOptionWithChildren = segmentOptions.splice(defaultSegmentIndex, childrenNumber + 1);
      return [...defaultOptionWithChildren, ...segmentGroupOptions, ...segmentOptions];
    }

    return [...segmentGroupOptions, ...segmentOptions];
  }

  private getGoalOptions(budgetObjects: BudgetObjectsData): SidebarHierarchyOption[] {
    const goalHierarchyMap = this.hierarchyBuilder.buildGoalHierarchy(budgetObjects);
    const goalOptions =
      Object.values(goalHierarchyMap.goals).sort(this.compareOptions).flatMap(
        goal => this.getHierarchyOptionsForGoal(goal)
      );

    const campaignOptions =
      Object.values(goalHierarchyMap.campaigns).sort(this.compareOptions).flatMap(
        campaign => this.getHierarchyOptionsForCampaign(2, campaign)
      );

    const expGroupOptions =
      Object.values(goalHierarchyMap.expGroups).sort(this.compareOptions).map(
        expGroup => this.getHierarchyOptionForExpenseGroup(2, expGroup)
      );

    const emptyOption = SpendingSidebarOptionsProviderService.getEmptyOption(SidebarObjectTypes.Goal);
    emptyOption.hasChildren = !!campaignOptions.length || !!expGroupOptions.length;
    emptyOption.collapsed = true;

    return [
      emptyOption,
      ...campaignOptions,
      ...expGroupOptions,
      ...goalOptions
    ];
  }

  private getCampaignOptions(filteredBudgetObjects: BudgetObjectsData): SidebarHierarchyOption[] {
    const filteredCampaignIds = filteredBudgetObjects.campaigns.map(item => item.id);

    const objectsHierarchyMap = this.hierarchyBuilder.buildCampaignHierarchy(filteredBudgetObjects, filteredCampaignIds);
    const campaignOptions =
      Object.values(objectsHierarchyMap.campaigns).sort(this.compareOptions).flatMap(
        campaign => this.getHierarchyOptionsForCampaign(1, campaign)
      );

    const expGroupOptions =
      Object.values(objectsHierarchyMap.expGroups).sort(this.compareOptions).map(
        expGroup => this.getHierarchyOptionForExpenseGroup(1, expGroup)
      );

    return [
      SpendingSidebarOptionsProviderService.getEmptyOption(SidebarObjectTypes.Campaign),
      ...campaignOptions,
      ...expGroupOptions
    ];
  }

  private getHierarchyOptionForExpenseGroup(level: number, expGroup: HierarchyExpenseGroupItem): SidebarHierarchyOption {
    if (!expGroup.data) {
      return null;
    }

    return {
      id: expGroup.data.id || expGroup.data.objectId,
      title: expGroup.data.name,
      level,
      objectType: SidebarObjectTypes.ExpenseGroup,
      visible: level === 1,
      closed: expGroup.data.mode === ObjectMode.Closed,
      segmentId: expGroup.data.budgetSegmentId,
      sharedCostRuleId: expGroup.data.splitRuleId
    };
  }

  private getHierarchyOptionsForCampaign(level: number, campaign: HierarchyCampaignItem): SidebarHierarchyOption[] {
    if (!campaign.data) {
      return null;
    }

    const campaignOption: SidebarHierarchyOption = {
      id: campaign.data.id || campaign.data.objectId,
      title: campaign.data.name,
      level,
      objectType: SidebarObjectTypes.Campaign,
      visible: level === 1,
      collapsed: true,
      hasChildren: !!campaign.childCampaigns?.length || !!campaign.childExpGroups?.length,
      closed: campaign.data.mode === ObjectMode.Closed,
      segmentId: campaign.data.budgetSegmentId,
      sharedCostRuleId: campaign.data.splitRuleId
    };

    return [
      campaignOption,
      ...(campaign.childCampaigns || []).sort(this.compareOptions)
        .flatMap(childCampaign => this.getHierarchyOptionsForCampaign(level + 1, childCampaign)),
      ...(campaign.childExpGroups || []).sort(this.compareOptions)
        .map(expGroup => this.getHierarchyOptionForExpenseGroup(level + 1, expGroup))
    ];
  }

  private getHierarchyOptionsForSegment(level: number, segment: HierarchySegmentItem): SidebarHierarchyOption[] {
    if (!segment.data) {
      return null;
    }

    const segmentOption: SidebarHierarchyOption = {
      id: segment.data.id,
      title: segment.data.name,
      level,
      objectType: SidebarObjectTypes.Segment,
      collapsed: true,
      visible: level === 1,
      hasChildren: !!segment.childCampaigns?.length || !!segment.childExpGroups?.length,
      isDefault: segment.data.name === this.configuration.defaultSegmentName && level === 1
    };

    return [
      segmentOption,
      ...(segment.childCampaigns || []).sort(this.compareOptions)
        .flatMap(campaign => this.getHierarchyOptionsForCampaign(level + 1, campaign)),
      ...(segment.childExpGroups || []).sort(this.compareOptions)
        .map(expGroup => this.getHierarchyOptionForExpenseGroup(level + 1, expGroup))
    ];
  }

  private getHierarchyOptionsForSegmentGroup(segmentGroup: HierarchySegmentGroupItem): SidebarHierarchyOption[] {
    const childSegmentIds = (segmentGroup.childSegments || []).map(segment => segment.data.id);
    const segmentGroupOption: SidebarHierarchyOption = {
      id: segmentGroup.data.id,
      title: segmentGroup.data.name,
      level: 1,
      objectType: SidebarObjectTypes.SegmentGroup,
      visible: true,
      collapsed: true,
      hasChildren: !!childSegmentIds?.length,
      groupSegmentIds: childSegmentIds
    };

    return [
      segmentGroupOption,
      ...(segmentGroup.childSegments || []).sort(this.compareOptions)
        .flatMap(segment => this.getHierarchyOptionsForSegment(2, segment))
    ];
  }

  private getHierarchyOptionsForGoal(goal: HierarchyGoalItem): SidebarHierarchyOption[] {
    if (!goal.data) {
      return null;
    }

    const goalOption: SidebarHierarchyOption = {
      id: goal.data.id,
      title: goal.data.name,
      level: 1,
      objectType: SidebarObjectTypes.Goal,
      collapsed: true,
      visible: true,
      hasChildren: !!goal.childCampaigns?.length || !!goal.childExpGroups?.length
    };

    return [
      goalOption,
      ...(goal.childCampaigns || []).sort(this.compareOptions)
        .flatMap(campaign => this.getHierarchyOptionsForCampaign(2, campaign)),
      ...(goal.childExpGroups || []).sort(this.compareOptions)
        .map(expGroup => this.getHierarchyOptionForExpenseGroup(2, expGroup))
    ];
  }

  private compareOptions(optionA: OptionItem, optionB: OptionItem): number {
    return optionA.data && optionB.data ? optionA.data.name.localeCompare(optionB.data.name) : 0;
  }
}
