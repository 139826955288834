import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UtilityService } from '@shared/services/utility.service';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Configuration } from 'app/app.constants';
import { switchMap, map, takeUntil, tap, filter } from 'rxjs/operators';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';
import { UserService } from '@shared/services/backend/user.service';
import { ChangePasswordRoutingStateData } from '../types/change-password-routing-state-data.type';
import { FederatedLoginSetupDialogComponent } from '@shared/modals/federated-login-setup-dialog/federated-login-setup-dialog.component';
import { CompanyDO } from '@shared/types/company.interface';
import { FederatedLoginSetupDialogContext } from '@shared/modals/federated-login-setup-dialog/federated-login-setup-dialog.type';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { CompanyUserService } from '@shared/services/backend/company-user.service';
import { DisableSsoDialogComponent } from '@shared/modals/disable-sso-dialog/disable-sso-dialog.component';
import { DisableSsoDialogContext } from '@shared/modals/disable-sso-dialog/disable-sso-dialog.type';
import { TeamMember } from '../../account/team-page/team-page.type';
import { UserDO } from '@shared/types/user-do.interface';
import { UserPermissionLevel } from '@shared/utils/common.utils';

const USER_ID_ATTRIBUTE_NAME = 'custom:id';
const IDENTITIES_ATTRIBUTE_NAME = 'identities';

@Injectable({
  providedIn: 'root'
})
export class UserManager implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  private readonly _currentUser = new BehaviorSubject<UserDO>(null);
  private readonly _currentCompanyUser = new BehaviorSubject<CompanyUserDO>(null);
  private _userPermissionLevel$ = new BehaviorSubject<UserPermissionLevel>(null);
  private readonly _afterLoggedIn = new Subject<void>();
  private readonly _afterLoggedOut = new Subject<void>();

  private _currentUserInfo: any;

  public readonly currentCompanyUser$ = this._currentCompanyUser.asObservable();
  public readonly afterLoggedIn$ = this._afterLoggedIn.asObservable();
  public readonly afterLoggedOut$ = this._afterLoggedOut.asObservable();
  public readonly currentUser$ = this._currentUser.asObservable();
  public userPermissionLevel$ = this._userPermissionLevel$.asObservable().pipe(filter(level => level !== null));

  private static hasIdentities(userInfo): boolean {
    const identitiesInfo = userInfo.attributes?.[IDENTITIES_ATTRIBUTE_NAME];
    let identities;
    try {
      identities = identitiesInfo && JSON.parse(identitiesInfo);
    } catch (e) {
      console.log('Failed to parse user identities info');
    }
    return Boolean(identities && identities.length);
  }

  constructor(
    private readonly utilityService: UtilityService,
    private readonly router: Router,
    public readonly configuration: Configuration,
    private readonly activatedRoute: ActivatedRoute,
    private readonly userService: UserService,
    private readonly companyUserService: CompanyUserService,
    private readonly matDialog: MatDialog
  ) {}

  public setLoggedIn() {
    this._afterLoggedIn.next();
  }

  public setLoggedOut() {
    this._userPermissionLevel$.next(UserPermissionLevel.None);
    this._afterLoggedOut.next();
  }

  private getCurrentUserInfo(): Observable<any> {
    return this._currentUserInfo ?
      of(this._currentUserInfo) :
      from(Auth.currentUserInfo().catch(() => null)).pipe(
        tap(info => this._currentUserInfo = info)
      );
  }

  public get currentUserId$(): Observable<number> {
    return this.getCurrentUserInfo().pipe(
      map(userInfo => userInfo && userInfo.attributes && userInfo.attributes[USER_ID_ATTRIBUTE_NAME])
    );
  }

  public get isSSOUser$(): Observable<boolean> {
    return this.getCurrentUserInfo().pipe(
      map(userInfo => !userInfo || UserManager.hasIdentities(userInfo))
    );
  }

  public getCurrentSession(): Observable<CognitoUserSession> {
    return from(
      Auth.currentSession().catch(() => null)
    );
  }

  public getCurrentAuthenticatedUser(): Observable<CognitoUser> {
    return from(
      Auth.currentAuthenticatedUser().catch(() => null)
    );
  }

  public login(email, password) {
    return from(Auth.signIn(email && email.toLowerCase(), password));
  }

  public logout(routeStateAfterLogout?: string) {
    this.utilityService.showLoading(true);
    from(Auth.signOut())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => this.onLogout(routeStateAfterLogout),
        error => this.utilityService.handleError(error)
      );
  }

  public invalidateSession() {
    return from(Auth.signOut({global: true}));
  }

  public isLoggedIn(): Observable<boolean> {
    return this.getCurrentSession().pipe(
      map(session => session && session.isValid())
    );
  }

  public getToken(): Observable<string> {
    return this.getCurrentSession().pipe(
      map(session => session && session.getIdToken().getJwtToken())
    );
  }

  public getCurrentUserObj() {
    return this._currentUser;
  }

  public getCurrentUser(): UserDO {
    return this._currentUser.value;
  }

  public getCurrentCompanyUser(): CompanyUserDO {
    return this._currentCompanyUser.value;
  }

  public setCurrentCompanyUser(companyUser: CompanyUserDO): void {
    this._currentCompanyUser.next(companyUser);
  }

  public clearCurrentCompanyUser(): void {
    this.setCurrentCompanyUser(null);
  }

  public loadCurrentUser(errorCb: (error: any) => void): void {
    this.currentUserId$
      .pipe(
        filter((userId) => userId != null),
        switchMap((userId) => this.userService.getUser(userId)),
        map((user) => {
          return {
            ...user,
            first_name: user?.user_profile_detail?.first_name,
            last_name: user?.user_profile_detail?.last_name
          };
        })
      )
      .subscribe({
        next: user => this._currentUser.next(user),
        error: err => errorCb?.(err)
      });
  }

  public setCurrentUser(user: UserDO): void {
    this._currentUser.next(user);
  }

  public setCurrentUserPermissionLevel(level: UserPermissionLevel): void {
    this._userPermissionLevel$.next(level);
  }

  private onLogout(routeStateAfterLogout?: string) {
    const currentUser = this._currentUser.value;
    if (currentUser) {
      this.utilityService.cleanUserData(currentUser.id);
      this._currentUser.next(null);
    }
    const currentCompanyUser = this.getCurrentCompanyUser();
    if (currentCompanyUser) {
      this.clearCurrentCompanyUser();
    }
    this._currentUserInfo = null;

    localStorage.removeItem('tooltip');
    localStorage.removeItem('show_pro_plan_alert');
    localStorage.removeItem('tab');

    this.router.navigate([{
      outlets: {
        primary: routeStateAfterLogout || this.configuration.ROUTING_CONSTANTS.LOGIN,
        [this.configuration.ROUTER_OUTLETS.DETAILS]: null,
        [this.configuration.ROUTER_OUTLETS.DRAWER]: null,
        [this.configuration.ROUTER_OUTLETS.DRAWER_STACK]: null
      }
    }]);

    this.setLoggedOut();
    this.utilityService.showLoading(false);
    sessionStorage.clear();
  }

  public afterAuthNavigate(routePathToNavigate: string): Observable<boolean | UrlTree> {
    const quickStartRoute = this.configuration.ROUTING_CONSTANTS.QUICK_START;

    const getNavigationUrlTree = (isRegistered) => {
      if (!isRegistered) {
        return !!routePathToNavigate.match(quickStartRoute) || this.router.createUrlTree([quickStartRoute]);
      }

      if (isRegistered && routePathToNavigate === quickStartRoute) {
        return this.router.createUrlTree([this.configuration.DEFAULT_ROUTE]);
      }

      return true;
    };

    return this.currentUserId$.pipe(
      switchMap(userId => userId ? this.userService.getUser(userId) : of(null)),
      map(user  => user ? getNavigationUrlTree(user.user_profile_detail.is_registered) : false)
    );
  }

  public completeNewPassword(user: CognitoUser, password: string) {
    return from(Auth.completeNewPassword(user, password, {}));
  }

  public changePassword(user: CognitoUser, oldPassword: string, newPassword: string) {
    return from(Auth.changePassword(user, oldPassword, newPassword));
  }

  public forgotPassword(email: string) {
    return from(Auth.forgotPassword(email && email.toLowerCase()));
  }

  public forgotPasswordSubmit(email: string, code: string, newPassword: string) {
    return from(Auth.forgotPasswordSubmit(email && email.toLowerCase(), code, newPassword));
  }

  public navigateToChangePassword(data?: ChangePasswordRoutingStateData) {
    return this.router.navigate(
      [this.configuration.ROUTING_CONSTANTS.CHANGE_PASSWORD],
      {state: {data}});
  }

  public trackFirstLogin(companyUser: CompanyUserDO) {
    const trackLoginInCompany$ =
      !companyUser || companyUser.first_login ?
        of (null) :
        this.companyUserService.trackFirstLogin(companyUser.company);

    trackLoginInCompany$.subscribe({
      error: () => console.error('Failed to track first login')
    });
  }

  navigateToCreateAccount() {
    if (this.configuration.register_url) {
      window.location.replace(this.configuration.register_url);
    } else {
      this.router.navigate([this.configuration.ROUTING_CONSTANTS.REGISTER]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  federatedLogin(providerName) {
    return from(
      Auth.federatedSignIn({customProvider: providerName})
    );
  }

  getCurrentDomainPrefix(): string | null {
    const hostName = window.location.hostname.toLowerCase();
    const envDomain = this.configuration.envDomain?.toLowerCase();
    const envDomainIndex = hostName.indexOf(envDomain);
    if (envDomainIndex <= 0) {
      return null;
    }
    const domainPrefix = hostName.slice(0, envDomainIndex - 1);
    return domainPrefix === this.configuration.region ? null : domainPrefix;
  }

  public setupFederatedLogin(company: CompanyDO) {
    const dialogContext: FederatedLoginSetupDialogContext = {
      companyId: company.id,
      ssoDomain: company.sso_domain,
      title: 'SSO Federated Login Set Up',
    };
    const dialogConfig: MatDialogConfig = {
      id: 'federated-login-setup',
      height: 'initial',
      width: '640px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'extended-confirmation-dialog',
      data: dialogContext
    };

    this.matDialog.open(FederatedLoginSetupDialogComponent, dialogConfig);
  }

  public disableSSO(company: CompanyDO) {
    const dialogContext: DisableSsoDialogContext = {
      title: 'Disabling SSO',
      companyId: company.id
    };
    const dialogConfig: MatDialogConfig = {
      id: 'disable-sso-dialog',
      height: 'initial',
      width: '460px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'extended-confirmation-dialog',
      data: dialogContext
    };

    this.matDialog.open(DisableSsoDialogComponent, dialogConfig);
  }

  public switchAccountOwner(currentOwner: TeamMember, newOwner: TeamMember): Observable<{user: number}> {
    return this.companyUserService.switchAccountOwner(currentOwner.companyUserId, newOwner.id);
  }
}
