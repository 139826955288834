import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { CompanyService } from 'app/shared/services/backend/company.service';
import { CompanyDO, CompanyFilterItem, CompanyIntegrationFlag, CompanyProperties } from 'app/shared/types/company.interface';
import { CompareService } from 'app/shared/services/compare.service';
import { CompareType } from 'app/shared/types/compare-type.type';
import { CompareDirection } from 'app/shared/types/compare-direction.type';
import { ManageCompanyService } from './manage_company.service';
import { MatDialog } from '@angular/material/dialog';
import { EnableSsoDialogComponent, EnableSsoDialogContext } from './enable-sso-dialog/enable-sso-dialog.component';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration'
import { EnableSaasOpticsIdComponent, EnableSaasOpticsIdContext } from './enable-saas-optics-id/enable-saas-optics-id.component';
import { SuperadminService } from '../shared/superadmin.service';
import { catchError, map, switchMap, tap, finalize, takeUntil } from 'rxjs/operators';
import { CompanySettingsStorageService } from '../../app/company-settings-storage.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

const ALL_COMPANIES = 'All Companies';
const FILTER_ID_DIVIDER = '&';

@Component({
  selector: 'app-root',
  templateUrl: './manage_company.component.html',
  styleUrls: ['./manage_company.component.scss'],
})
export class ManageCompanyComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  users: any[] = [];
  search = '';
  companyData: CompanyDO[] = [];
  isAllCheckedCompany = false;
  checks: any = [];
  disableEditAll = true;
  planTypeDropdownSettings = <any>{};
  filter_data: any[] = [];
  selectedFilter = [];
  companyOrderBy = '';
  companySortBy = CompareDirection.Desc;
  filter_by = '';
  @ViewChild('.table-superAdminCompany', { static: true }) table;
  enableCompanySettings = false;
  companySettingsData: updateCompanySettingsDialogContext[];
  filteredCompanySettingsData: updateCompanySettingsDialogContext[];
  editingIndex: number | null = null;
  selectedCompanyName: string;
  itemSearched = false;

  batchSize = 50; // adjust the batch size as needed
  offset = 0;
  loading = false;

  showFilter = false;
  filters = {
    param_key: '',
    param_value: '',
    component: '',
    description: '',
    allowed_values: ''
  };

  private uniqueDomains = new Set<string>();

  public localSortField = '';
  public localSortDirection = CompareDirection.Desc;
  public CompanyIntegrationSources = CompanyIntegrationFlag;
  public CompanyProperties = CompanyProperties;
  public compareTypes = CompareType;

  destroy$ = new Subject<void>();
  planNames = this.configuration.planNames;
  filterOptions: CompanyFilterItem[] = [
    { type: 'plan_code', value: this.planNames.freePlan, title: 'Free Plan' },
    { type: 'plan_code', value: this.planNames.soloPlan, title: 'Solo Plan' },
    { type: 'plan_code', value: this.planNames.proPlan, title: 'Pro Plan' },
    { type: 'plan_code', value: this.planNames.compPlan, title: 'Comp Plan' },
    { type: 'status', value: 'Inactive', title: 'Inactive' },
    { type: 'non_reporting', value: 'True', title: 'Non-Reporting' },
    { type: '', value: '', title: ALL_COMPANIES },
  ];

  actionsOptionsForDisplay = null;
  actionsOptionsAll = [
    {
      id: 'settings',
      title: 'Company Settings',
      directAction: this.updateCompanySettings.bind(this)
    },
    {
      id: 'account_status',
      title: 'Account Status',
      values: [{
        text: 'Active',
        cb: this.updateCompaniesProperty.bind(this, CompanyProperties.Status, 'Active'),
      },
      {
        text: 'Inactive',
        cb: this.updateCompaniesProperty.bind(this, CompanyProperties.Status, 'Inactive'),
      }]
    },
    {
      id: 'adopted',
      title: 'Adopted',
      values: [{
        text: 'Yes',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.Adopted, true),
      },
        {
          text: 'No',
          cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.Adopted, false),
        }]
    },
    {
      id: 'budgie',
      title: 'Budgie',
      values: [{
        text: 'Enable',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.Budgie, true),
      },
        {
          text: 'Disable',
          cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.Budgie, false),
        }]
    },
    {
      id: 'default_timeframe_suppression',
      title: 'Default Timeframe Suppression',
      values: [{
        text: 'Enable',
        cb: this.updateCompaniesProperty.bind(this, CompanyProperties.DefaultForSuppressTimeframeAllocations, true),
      },
        {
          text: 'Disable',
          cb: this.updateCompaniesProperty.bind(this, CompanyProperties.DefaultForSuppressTimeframeAllocations, false),
        }]
    },
    {
      id: 'facebook_ads',
      title: 'Facebook Ads',
      values: [{
        text: 'Enable',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.FacebookAds, true),
      },
        {
          text: 'Disable',
          cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.FacebookAds, false),
        }]
    },
    {
      id: 'google_ads',
      title: 'Google Ads',
      values: [{
        text: 'Enable',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.GoogleAds, true),
      },
        {
          text: 'Disable',
          cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.GoogleAds, false),
        }]
    },
    {
      id: 'hubspot',
      title: 'Hubspot',
      values: [{
        text: 'Enable',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.Hubspot, true),
      },
        {
          text: 'Disable',
          cb: this.disableMetricIntegrationFlag.bind(this, MetricIntegrationName.Hubspot),
        }]
    },
    {
      id: 'linkedin_ads',
      title: 'LinkedIn Ads',
      values: [{
        text: 'Enable',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.LinkedinAds, true),
      },
        {
          text: 'Disable',
          cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.LinkedinAds, false),
        }]
    },
    {
      id: 'reporting_status',
      title: 'Reporting Status',
      values: [{
        text: 'Reporting',
        cb: this.updateCompaniesProperty.bind(this, CompanyProperties.NonReporting, false),
      },
        {
          text: 'Non-Reporting',
          cb: this.updateCompaniesProperty.bind(this, CompanyProperties.NonReporting, true),
        }]
    },
    {
      id: 'saasOpticsId',
      title: 'SaaSOptics ID',
      values: [{
        text: 'Enable',
        cb: this.updateSaasOpticsId.bind(this),
      },
        {
          text: 'Disable',
          cb: this.disableSaasOpticsId.bind(this),
        }]
    },
    {
      id: 'salesforce',
      title: 'Salesforce',
      values: [{
        text: 'Enable',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.Salesforce, true),
      },
      {
        text: 'Disable',
        cb: this.disableMetricIntegrationFlag.bind(this, MetricIntegrationName.Salesforce),
      }]
    },
    {
      id: 'sso',
      title: 'SSO',
      values: [{
        text: 'Enable',
        cb: this.enableSSO.bind(this),
      },
      {
        text: 'Disable',
        cb: this.updateCompaniesProperty.bind(this, CompanyIntegrationFlag.SSO, false),
      }]
    },
    {
      id: 'delete',
      title: 'Delete',
      directAction: this.deleteCompanies.bind(this)
    },
  ];

  disableClearStaleData: boolean = false;

  constructor(
    protected readonly configuration: Configuration,
    protected readonly utilityService: UtilityService,
    protected readonly validations: Validations,
    private readonly companyDataService: CompanyDataService,
    protected readonly userManager: UserManager,
    private readonly companyService: CompanyService,
    private readonly manageCompanyService: ManageCompanyService,
    private readonly dialog: MatDialog,
    private readonly superadminService: SuperadminService,
    private readonly companySettingsStorageService: CompanySettingsStorageService,
  ) {

    this.planTypeDropdownSettings = {
      singleSelection: true,
      text: 'Filter',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: 'select-category',
      badgeShowLimit: 1
    };
  }

  ngOnInit() {
    this.search = localStorage.getItem('searchCompany') || '';
    this.getCompaniesList();

    this.filter_data = this.filterOptions.map(item => {
     return {
       id: item.type + FILTER_ID_DIVIDER + item.value,
       itemName: item.title,
     }
    });

    this.selectedFilter = [this.filter_data.find(el => el.itemName === ALL_COMPANIES)];
  }

  getCompaniesList(filterBy?, isFromScroll = false) {
    if (this.loading) return;
    this.loading = true;
    if(!isFromScroll) {
    this.utilityService.showLoading(true);
    }
    this.checks = [];
    this.isAllCheckedCompany = false;
    this.filter_by = filterBy;
    this.disableEditAll = true;
    this.disableClearStaleData = false;
    this.actionsOptionsForDisplay = null;
    this.table ? this.table.nativeElement.scrollTop = 0 : null;
    let dataObj = {};
    if (this.filter_by)	{
      const [type, value] = this.filter_by.split(FILTER_ID_DIVIDER);
      dataObj[type] = value;

      if (!type) { // means 'All companies' selected
        dataObj = {};
        // TODO: check all cases where we need reset sorting
        this.companySortBy = null;
        this.companyOrderBy = '';
        this.filter_by = '';
      }
    }

    if (this.search) {
      this.itemSearched = true;
      dataObj['search'] = this.search;
    }
    else {
      this.itemSearched = false;
    }

    if (this.companySortBy === CompareDirection.Asc && this.companyOrderBy !== '') {
      dataObj['ordering'] = this.companyOrderBy;
    } else if (this.companySortBy === CompareDirection.Desc && this.companyOrderBy !== '') {
      dataObj['ordering'] = '-' + this.companyOrderBy;
    }
    this.superadminService.getCompaniesListForSuperAdmin(dataObj, this.offset, this.batchSize).subscribe(
      data => this.success(data, 'getCompanylist'),
      error => this.HandleError(error)
    );
  }

  checkAll({checked}, companyLength) {
    for (let i = 0; i < companyLength; i++) {
      this.checks[i] = checked;
    }
    this.isAllChecked(companyLength);
  }

  checkCompany(checked: boolean, index: number, companyLength: number): void {
    this.checks[index] = checked;
    this.isAllChecked(companyLength);
  }

  isAllChecked(companyLength) {
    if (this.checks.length > 0 && this.checks.length === companyLength) {
      if (this.checks.indexOf(false) > -1 || this.checks[0] == null) {
        this.isAllCheckedCompany = false;
      } else {
        let anyIndexNotExists = false;
        for (let i = 0; i < this.checks.length; i++) {
          if (this.checks[i] == null) {
            anyIndexNotExists = true;
          }
        }
        this.isAllCheckedCompany = !anyIndexNotExists;
      }
    } else {
      this.isAllCheckedCompany = false;
    }
    this.actionsOptionsForDisplay = null;
    this.isDisableEditAll();
  }

  isDisableEditAll() {
    this.disableEditAll = !!this.checks.length ? this.checks.indexOf(true) === -1 : true;
    this.disableClearStaleData = !this.disableEditAll;
  }

  toggleActionsDropDown() {
    this.actionsOptionsForDisplay = this.actionsOptionsForDisplay ? null : this.getOptionsList;
  }

  get getOptionsList() {
    const selectedQty = this.getSelectedCompanyIds().length;
    if (!selectedQty) {
      return null;
    }
    if (selectedQty > 1) {
      // hide SSO in case selected more than one company
      return this.actionsOptionsAll.filter(option => option.id !== 'sso' && option.id !== 'settings');
    }
    return this.actionsOptionsAll;
  }

  getSelectedCompanyIds(): number[] {
    const selectedCompanyIds = [];
    for (let i = 0; i < this.companyData.length; i++) {
      if (this.checks[i]) {
        selectedCompanyIds.push(this.companyData[i]['id']);
      }
    }

    return selectedCompanyIds;
  }

  public trackCompanyFn(company) {
    return company.id;
  }

  private onBulkUpdateComplete() {
    this.checks = [];
    this.isAllCheckedCompany = false;
    this.disableEditAll = true;
    this.disableClearStaleData = false;
    this.actionsOptionsForDisplay = null;
    this.utilityService.showLoading(false);
  }

  public updateCompaniesProperty(integrationFlag: CompanyIntegrationFlag, status: boolean | string) {
    this.actionsOptionsForDisplay = null;
    const selectedIds = this.getSelectedCompanyIds();
    const payload = { [integrationFlag]: status };
    this.utilityService.showLoading(true);
    this.manageCompanyService.requestMultiUpdate(selectedIds, payload, this.companyData)
      .subscribe({
        complete: () => { this.onBulkUpdateComplete(); }
      });
  }

  public enableSSO() {
    this.actionsOptionsForDisplay = null;
    const selectedIds = this.getSelectedCompanyIds();
    if (selectedIds.length === 1) {
      const selectedId = selectedIds[0];
      const company = this.companyData.find(cmp => cmp.id === selectedId);
      this.openConfirmEnableSSODialog(selectedId, company.sso_domain);
    }
  }

  public updateSaasOpticsId() {
    this.actionsOptionsForDisplay = null;
    const selectedIds = this.getSelectedCompanyIds();
    if (selectedIds.length === 1) {
      const selectedId = selectedIds[0];
      const company = this.companyData.find(cmp => cmp.id === selectedId);
      this.openConfirmSaasOpticsIdDialog(selectedId, company.saas_optics_customer_id);
    }
  }

  public disableSaasOpticsId() {
    this.actionsOptionsForDisplay = null;
    const selectedIds = this.getSelectedCompanyIds();
    if (selectedIds.length === 1) {
      this.utilityService.showLoading(true);
      this.companyService.updateCompany(
        selectedIds[0],
        { [CompanyIntegrationFlag.SaasOpticsCustomerId]: null }
      ).subscribe(
        () => {
          const company = this.companyData.find(cmp => cmp.id === selectedIds[0]);
          company.saas_optics_customer_id = null;
          this.utilityService.showLoading(false);
        },
        () => {
          this.utilityService.handleError({ message: 'Failed to disable SaaSOptics ID' });
          this.utilityService.showLoading(false);
        }
      );
    }
  }

  public disableMetricIntegrationFlag(integrationSource: MetricIntegrationName) {
    this.actionsOptionsForDisplay = null;
    this.manageCompanyService.disableMetricIntegration(this.getSelectedCompanyIds(), this.companyData, integrationSource)
      .subscribe({
        complete: () => { this.onBulkUpdateComplete(); }
      });
  }

  public deleteCompanies(): void {
    this.actionsOptionsForDisplay = null;
    const ids = this.getSelectedCompanyIds();

    const okHandler = () => {
      this.utilityService.showLoading(true);

      return forkJoin(
        ids.map(companyId => this.deleteCompany(companyId))
      ).pipe(
        tap(results => this.processDeletion(results))
      );
    };

    this.manageCompanyService.confirmDelete(ids, this.companyData, okHandler)
      .subscribe({
        complete: () => { this.onBulkUpdateComplete(); }
      });
  }

  private deleteCompany(id: number): Observable<{ id: number, status: string }> {
    const hasSsoIdentityProvider = !!this.companyData.find(cmp => cmp.id === id)?.sso_identity_provider;
    const disableSSO$ = hasSsoIdentityProvider ? this.companyService.disableSSO(id) : of(null);

    return disableSSO$.pipe(
      switchMap(() => this.companyService.deleteCompany(id)),
      map(() => ({ id, status: 'success' })),
      catchError(() => of({ id, status: 'error' }))
    );
  }

  private processDeletion(results: { id: number, status: string }[]): void {
    const failedResults = [];
    const succeededResults = [];
    for (const result of results) {
      if (result.status === 'error') {
        failedResults.push(result);
      } else {
        succeededResults.push(result);
      }
    }

    if (succeededResults.length) {
      this.companyData = this.companyData.filter(company => !succeededResults.find(result => result.id === company.id));
    }

    const message = this.getDeleteResultMessage(failedResults, succeededResults);
    this.utilityService.showToast({ Title: '', Message: message });
  }

  private getDeleteResultMessage(failedResults: { id: number, status: string }[], succeededResults: { id: number, status: string }[]): string {
    const failedIds = failedResults.map(({ id }) => id).join(', ');
    const companyTitle = (length: number) => (length === 1 ? 'company' : 'companies');

    if (failedResults.length && succeededResults.length) {
      return `${succeededResults.length} ${companyTitle(succeededResults.length)} deleted successfully, but failed to delete the companies with ids: ${failedIds}.`;
    } else if (failedResults.length) {
      return `Failed to delete the companies with ids: ${failedIds}.`;
    } else {
      return `${succeededResults.length} ${companyTitle(succeededResults.length)} deleted successfully.`;
    }
  }

  public openConfirmEnableSSODialog(companyId: number, customDomain: string) {
    const dialogConfig = {
      width: '574px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'extended-confirmation-dialog'
    };

    const enableSSO = newCustomDomain => {
      this.utilityService.showLoading(true);
      this.companyService.updateCompany(
        companyId,
        {[this.CompanyIntegrationSources.SSO]: true, 'sso_domain': newCustomDomain}
      ).subscribe(
        () => {
          const company = this.companyData.find(cmp => cmp.id === companyId);
          const previousDomain = company.sso_domain;
          this.uniqueDomains.delete(previousDomain);
          this.uniqueDomains.add(newCustomDomain);
          company.sso = true;
          company.sso_domain = newCustomDomain;
          this.utilityService.showLoading(false);
        },
        () => {
          this.utilityService.handleError({ message: 'Failed to enable SSO' });
          this.utilityService.showLoading(false);
        }
      );
    };

    const dialogData: EnableSsoDialogContext = {
      customDomain,
      existingDomains: this.getExistingCustomDomains(),
      okHandler: enableSSO
    };

    return this.dialog.open(EnableSsoDialogComponent, {
      ...dialogConfig,
      data: dialogData
    });
  }


  public openConfirmSaasOpticsIdDialog(companyId, saaSOpticsId: string) {
    const dialogConfig = {
      width: '574px',
      autoFocus: false,
      restoreFocus: false,
      panelClass: 'extended-confirmation-dialog'
    };

    const okHandler = newSaaSOpticsId => {
      this.utilityService.showLoading(true);
      this.companyService.updateCompany(
        companyId,
        { [CompanyIntegrationFlag.SaasOpticsCustomerId]: newSaaSOpticsId || null }
      ).subscribe(
        () => {
          const company = this.companyData.find(cmp => cmp.id === companyId);
          company.saas_optics_customer_id = newSaaSOpticsId;
          this.utilityService.showLoading(false);
        },
        () => {
          this.utilityService.handleError({ message: 'Failed to enable SaaSOptics ID' });
          this.utilityService.showLoading(false);
        }
      );
    };

    const dialogData: EnableSaasOpticsIdContext = {
      okHandler,
      saaSOpticsId,
    };

    return this.dialog.open(EnableSaasOpticsIdComponent, {
      ...dialogConfig,
      data: dialogData
    });
  }

  private updateCompanySettings() {
    this.utilityService.showLoading(true);
    const ids = this.getSelectedCompanyIds();
    this.selectedCompanyName = this.companyData.find(cmp => cmp.id === ids[0]).name;

    this.companySettingsStorageService.fetchCompanySettings(ids[0]).subscribe(
      data => {
      this.utilityService.showLoading(false);
      this.companySettingsData = data;
      this.filteredCompanySettingsData = [...this.companySettingsData];
      this.enableCompanySettings = true;
      },
      error => {
        this.utilityService.handleError({ message: 'Failed to fetch company settings' });
        this.utilityService.showLoading(false);
      });
  }

  saveSetting(company: updateCompanySettingsDialogContext, index: number) {
    const allowedValuesArray = company.allowed_values.split(',').map(value => value.trim());
    if (allowedValuesArray.includes(company.param_value)) {
      this.companySettingsStorageService.updateCompanySettings(company.id, company.param_key, company.param_value).pipe(
        catchError(error => {
          this.utilityService.showToast({ Title: '', Message: 'Failed to update Value', Type: 'error' });
          console.error('Failed to update company settings:', error);
          return of(null);
        })
      ).subscribe(response => {
        if (response) {
          this.utilityService.showToast({ Title: '', Message: 'Value updated successfully', Type: 'success' });
          this.editingIndex = null;
        } else {
          this.utilityService.showToast({ Title: '', Message: 'Failed to update Value', Type: 'error' });
        }
      });
    } else {
      this.utilityService.showToast({ Title: '', Message: 'Entered value is not available in Allowed Values', Type: 'error' });
    }
  }

  editSetting(company: updateCompanySettingsDialogContext, index: number) {
    this.editingIndex = index;
  }

  enableCompanySettingsFilter() {
    this.showFilter = !this.showFilter;
    if (!this.showFilter) {
      this.clearFilters();
    }
  }

  goBack() {
    this.enableCompanySettings = false;
    this.companySettingsData = [];
    this.filteredCompanySettingsData = [];
    this.showFilter = false;
  }

  applyFilter(column: string, value: string) {
    this.filters[column] = value;
    this.filteredCompanySettingsData = this.companySettingsData.filter(setting =>
      Object.keys(this.filters).every(
        key => setting[key].toString().toLowerCase().includes(this.filters[key].toLowerCase())
      )
    );
  }

  clearFilters() {
    this.filters = {
      param_key: '',
      param_value: '',
      component: '',
      description: '',
      allowed_values: ''
    };
    this.filteredCompanySettingsData = [...this.companySettingsData];
  }

  clearSearch() {
    this.search = '';
    this.companyData = [];
    this.getCompaniesList(this.filter_by);
  }

  private getExistingCustomDomains(): string[] {
    return Array.from(this.uniqueDomains.values());
  }

  onSelectFilter(item: any, event) {
    if (event) {
      const planTypeDropdown = <HTMLElement>document.querySelector('#planTypeDropdown');
      planTypeDropdown.click();
    }

    setTimeout(() => {
      this.companyData = [];
      this.offset = 0;
      this.getCompaniesList(item.id);
    }, 100);
  }

  onDeSelectFilter(item: any) {
    this.selectedFilter[0] = item;
    const planTypeDropdown = <HTMLElement>document.querySelector('#planTypeDropdown');
    planTypeDropdown.click();
  }

  companySort(order_by) {
    if (this.companyOrderBy === order_by) {
      if (this.companySortBy === CompareDirection.Asc) {
        this.companySortBy = CompareDirection.Desc;
      } else {
        this.companySortBy = CompareDirection.Asc;
      }
    } else {
      this.companyOrderBy = order_by;
      this.companySortBy = CompareDirection.Asc;
    }

    if (this.companyData.length > 0) {
      this.companyData = [];
      this.offset = 0;
      this.getCompaniesList(this.filter_by);
    }
  }

  searchData() {
    this.companyData = [];
    this.offset = 0;
    this.getCompaniesList(this.filter_by);
  }

  sortCompaniesLocally(sortField, compareType = CompareType.Numerical) {
    const comparer = CompareService.comparerByType[compareType];

    if (this.localSortField === sortField) {
      this.localSortDirection = this.localSortDirection === CompareDirection.Asc ? CompareDirection.Desc : CompareDirection.Asc;
    } else {
      this.localSortField = sortField;
      this.localSortDirection = CompareDirection.Asc;
    }
    if (this.companyData.length > 0 && typeof comparer === 'function') {
      this.companyData = this.companyData.sort((itemA, itemB) => {
        return comparer(itemA[this.localSortField], itemB[this.localSortField], this.localSortDirection);
      });
    }
  }

  private cacheUniqueDomains() {
    this.companyData.forEach(({ sso_domain }) => {
      if (!sso_domain || this.uniqueDomains.has(sso_domain)) {
        return;
      }

      this.uniqueDomains.add(sso_domain);
    });
  }

  success(data, type) {
    if (data && data.results) {
      if (type === 'getCompanylist') {
        this.companyData = [...this.companyData, ...data.results]; // append new data to existing array
        this.cacheUniqueDomains();
        localStorage.removeItem('searchCompany');
        this.utilityService.showLoading(false);
        this.loading = false; // reset loading flag
      } else {
        this.utilityService.showToast({Title: '', Message: data.message, Type: 'error'});
        this.utilityService.showLoading(false);
      }
    }
  }

  onScroll(event: any) {
    const scrollContainer = event.target;
    const scrollPosition = scrollContainer.scrollTop + scrollContainer.offsetHeight;
    const scrollHeight = scrollContainer.scrollHeight;
    if (scrollPosition >= scrollHeight) {
      this.onScrollToEnd();
    }
  }

  onScrollToEnd() {
    if (!this.loading) {
      this.offset = this.companyData.length;
      this.getCompaniesList(this.filter_by, true);
    }
  }

  HandleError(error) {
    if (error.hasOwnProperty('message')) {
      this.utilityService.showToast({ Title: '', Message: error.message, Type: 'error' });
      this.utilityService.showLoading(false);
    } else if (error.hasOwnProperty('detail')) {
      this.utilityService.showLoading(false);
      this.utilityService.showToast({ Title: '', Message: error.detail, Type: 'error' });
    }
  }

  clearStaleData() {
    this.disableClearStaleData = true;
    this.manageCompanyService.clearStaleData().pipe(
      takeUntil(this.destroy$),
      finalize(() => this.disableClearStaleData = false)
    ).subscribe({
      complete: () => {
        this.utilityService.showToast(
          { Title: '', Message: 'Clear action completed' }
        );
      },
      error: () => {
        this.utilityService.showToast(
          { Title: '', Message: 'Clear action could not be completed. Please retry.' },
          false,
          true
        );
      }
    });
  }

  registerFreeAccount() {
    this.utilityService.setNoRegistrationFlag();
    this.userManager.logout(this.configuration.ROUTING_CONSTANTS.REGISTER);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}


export interface updateCompanySettingsDialogContext {
  id: number;
  company_id: number;
  param_key: string;
  param_value: string;
  component: string;
  description: string;
  allowed_values: string;
  editable: boolean;
}
