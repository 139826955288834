import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { CustomFieldsService, CustomFieldStatusAndCountDO } from '../../budget-object-details/components/custom-fields/custom-field.service';
import { UtilityService } from './utility.service';
import { BudgetObjectDialogService } from './budget-object-dialog.service';
import { UserManager } from '../../user/services/user-manager.service';

@Injectable({
  providedIn: 'root'
})
export class CustomFieldStatusService {
  private _customFieldStatus$: BehaviorSubject<CustomFieldStatusAndCountDO | null> =
    new BehaviorSubject<CustomFieldStatusAndCountDO | null>(null);

  constructor(
    private customFieldsService: CustomFieldsService,
    private utilityService: UtilityService,
    private dialogManager: BudgetObjectDialogService
  ) {}
  
  public fetchCustomFieldStatus(): Observable<CustomFieldStatusAndCountDO> {
    if (this._customFieldStatus$.getValue() !== null) {
      return this._customFieldStatus$.asObservable();
    }
  
    return this.customFieldsService.getCFStatus().pipe(
      take(1),
      tap(res => {
        this._customFieldStatus$.next(res); // Cache the response
      }),
      catchError(error => {
        console.error('Error fetching custom field status:', error);
        this.utilityService.handleError(error);
        this._customFieldStatus$.next(null); // Store null on error
        return of(null);
      })
    );
  }

  public hasCustomFieldEnabled(): Observable<boolean> {
    return this.fetchCustomFieldStatus().pipe(
      map(res => res?.isCustomFieldEnabled ?? false)
    );
  }

  public refreshCustomFieldStatusForSelectedCompany(): void {
    this.customFieldsService.getCFStatus().pipe(
      take(1),
      tap(status => this._customFieldStatus$.next(status)),
      catchError(error => {
        this.utilityService.handleError(error);
        this._customFieldStatus$.next(null);
        return of(null);
      })
    ).subscribe();
  }
  

  public openNoCustomFieldsFoundModal(isAdmin): void {
      const content = isAdmin
        ? `Custom fields do not exist for the selected objects. <br><br>
            Create one in Admin Settings. <a href="https://help.planful.com/planful-for-marketing/docs/custom-fields" target="_blank">Learn More</a>`
        : `Custom fields do not exist for the selected objects. <br><br> Contact your admin to create one.`;

      this.dialogManager.openConfirmationDialog({
        title: 'No Custom Fields Found',
        content: content,
        submitAction: {
          label: 'Ok',
          handler: () => null
        }
      }, {
        width: '480px',
        panelClass: 'no-cf-confirmation-dialog'
      });    
  }
}