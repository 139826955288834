<div
  *ngFor="let option of flatOptions"
  class="flat-option"
  [ngClass]="{
    'default': option.isDefault,
    'hidden': option.searchHidden,
    'selected': isOptionSelected(option),
    'drop-allow': onDragOverItem[option?.id] === dropState.ALLOW,
    'drop-forbid': onDragOverItem[option?.id] === dropState.FORBID,
    'glcode-option': option.objectType === sidebarObjectTypes.GlCode
  }"
  [plDroppable]="true"
  (dragover)="onHandleDragOver($event, option)"
  (dragleave)="onHandleDragLeave($event, option.id)"
  (dropOn)="onHandleDrop(option)"
  (click)="toggleSelectedState(option)"
>
  <ng-container [ngSwitch]="viewMode">
    <fa-duotone-icon
      *ngSwitchCase="hierarchyViewMode.GlCode"
      class="option-icon"
      [icon]="['fad', 'barcode-alt']"
    ></fa-duotone-icon>
    <fa-duotone-icon
      *ngSwitchCase="hierarchyViewMode.Timeframe"
      class="option-icon"
      [icon]="['fad', 'calendar-alt']"
    ></fa-duotone-icon>
    <fa-duotone-icon
      *ngSwitchCase="hierarchyViewMode.Source"
      class="option-icon plug-icon"
      [icon]="['fad', viewModeCustomIcons.PLUG]"
      [rotate]="90"
    ></fa-duotone-icon>
    <fa-icon
      *ngSwitchCase="hierarchyViewMode.Status"
      class="option-icon"
      [icon]="['fas', viewModeCustomIcons.HEART_RATE]"
    ></fa-icon>
    <icon-user-helmet-safety
      *ngSwitchCase="hierarchyViewMode.Vendor"
      class="option-icon"
      color="#061F38"
    ></icon-user-helmet-safety>
  </ng-container>

  <span class="option-title" [innerHTML]="option.title | highlight: searchQuery"></span>
  <fa-icon *ngIf="option.locked" [icon]="['fad', 'lock-alt']" class="lock-icon"></fa-icon>
  <span class="counter">{{ getOptionExpenseCount(option) | number: numberFormat }}</span>
  <!-- Show Description of GL code in Sidebar Hierarchy view for Expenses Page -->
  <div *ngIf="option.objectType === sidebarObjectTypes.GlCode" class="option-description" [innerHTML]="option.description"></div>   
</div>
