import { LightObject, SegmentedBudgetObject } from './segmented-budget-object';
import { SegmentedObjectTimeframe } from './object-timeframe.interface';
import { ProgramAllocation } from './budget-object-allocation.interface';
import { BudgetObjectTotals } from './budget-object-totals.interface';
import { AttachmentMappingDO } from './attachment.interface';
import { CategorisedObject, PlanObjectDO, SegmentedObjectDO } from './plan.type';
import { BudgetObjectSource } from '../../budget-object-details/types/budget-object-details-state.interface';

export interface ProgramHierarchyData {
  campaignId?: number;
  goalId?: number;
  subProgramId?: number; // Id of a source program for a pseudo-program
}

export interface Program extends SegmentedBudgetObject, ProgramHierarchyData {
  programTypeId: number;
  timeframes: SegmentedObjectTimeframe[];
  glCode?: number;
  poNumber?: string;
}

export interface LightProgram extends LightObject, ProgramHierarchyData {
  programTypeId: number;
}

export interface ProgramDO {
  id: number;
  start_date?: string;
  end_date?: string;
  program_id?: number;
  program?: number; // the same as program_id, but for short version of program
  amount: number;
  forecast_amount?: number;
  source_amount: number;
  budget: number;
  company: number;
  company_budget_segment1?: number;
  program_allocations: ProgramAllocation[];
  program_type: number;
  split_rule?: number;
  po_number: string;
  gl_code: number;
  created_by: number;
  owner: number;
  mode: string;
  name: string;
  note: string;
  crd?: string;
  upd?: string;
  status_totals: BudgetObjectTotals;
  attachments: AttachmentMappingDO[];
  campaign?: number;
  campaign_id?: number;
  goal?: number;
  // Special flag for the API to decide whether we should sync children segments or not
  process_nested?: boolean;
  campaignSource?: string;
  external_id?: string;
  object_type?: number;
  amount_status?: string;
  source_currency: string;
  vendor: number;
  source: BudgetObjectSource;
  custom_fields?: Record<number, string[]>;
}

export interface ProgramTypeDO {
  id: number;
  name: string;
  is_custom: boolean;
  crd: string;
  upd: string;
  company: number;
  usage_count?: number;
}

export interface PlanProgramExpense extends SegmentedObjectDO, CategorisedObject {
  id: number;
  name: string;
}

export interface PlanProgramDO extends PlanObjectDO {
  program_type: number;
  expense_list: PlanProgramExpense[];
}
