import { LightCampaign } from 'app/shared/types/campaign.interface';
import { LightProgram } from 'app/shared/types/program.interface';
import { Goal } from '@shared/types/goal.interface';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { SegmentGroup } from 'app/shared/types/segment-group.interface';
import { SharedCostRule } from 'app/shared/types/shared-cost-rule.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { GLCode, Vendor } from 'app/shared/services/company-data.service';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';

export enum SpendingManagementMode {
  Expenses = 'expenses',
  Invoices = 'invoices',
}

export enum HierarchyViewMode {
  Segment = 'segment',
  Goal = 'goal',
  Campaign = 'campaign',
  GlCode = 'glCode',
  Source = 'source',
  Status = 'status',
  Vendor = 'vendor',
  Timeframe = 'timeframe',
}

export enum SidebarObjectTypes {
  Segment = 'Segment',
  SegmentGroup = 'SegmentGroup',
  Campaign = 'Campaign',
  Goal = 'Goal',
  GlCode = 'GlCode',
  Source = 'Source',
  Status = 'Status',
  Vendor = 'Vendor',
  Timeframe = 'Timeframe',
  ExpenseGroup = 'ExpenseGroup'
}

export enum ExpenseCustomNameColumn {
  ExpenseType = 'expenseType',
  GlCode = 'GlCode',
  Vendor = 'Vendor',
}

export enum ViewModeCustomIcons {
  PLUG = 'plug',
  HEART_RATE = 'heart-rate',
  USER_HELMET_SAFETY = 'user-helmet-safety'
}

export enum ExpenseTableColumn {
  NAME,
  PLANNED,
  ACTUAL,
  DIFFERENCE,
  STATUS,
  TIMEFRAME,
  CURRENCY,
  SEGMENT,
  PARENT,
  SOURCE,
  GL_CODE,
  VENDOR,
  CREATED_DATE,
  UPDATED_DATE,
  DELIVERY_DATE,
  ATTACHMENT,
  TYPE,
  OWNER,
  RELATED,
  INVOICE,
  PO_NUMBER,
}

export interface ExpenseTableColumnItem {
  id: ExpenseTableColumn;
  title: string;
  visible?: boolean;
  draggable?: boolean;
  align: 'flex-start' | 'center' | 'flex-end';
}

export interface SidebarHierarchyOption {
  id: number | string; // contains object id or value (for Status and Source)
  title: string;
  level?: number;
  objectType: SidebarObjectTypes;
  visible: boolean;
  closed?: boolean; // for closed Campaigns and Expense Groups
  collapsed?: boolean;
  searchHidden?: boolean;
  hasChildren?: boolean;
  isDefault?: boolean;
  segmentId?: number;
  sharedCostRuleId?: number;
  groupSegmentIds?: number[];
  locked?: boolean; // for locked Timeframes
  description?: string;
}

export enum SortByValue {
  Ascending = '',
  Descending = '-',
  Name = 'name',
  ExpenseType = 'expense_type__name',
  Owner = 'owner__email', // order by owner email
  Segment = 'company_budget_segment1__name',
  Parent = 'parent', //order by expense parent(campaign/program)
  Currency = 'source_currency__name',
  PlannedAmount = 'amount',
  ActualAmount = 'actual_amount',
  Status = 'mode',
  Timeframe = 'company_budget_alloc__order',
  CreatedDate = 'crd',
  UpdatedDate = 'upd',
  InvoiceNumber = 'invoice',
  PONumber = 'expense_po_no',
  Vendor = 'vendor__name'
}

export enum SortByLabel {
  Ascending = 'Ascending',
  Descending = 'Descending',
  // Expenses
  Name = 'Name',
  ExpenseType = 'Expense Type',
  Owner = 'Owner',
  Segment = 'Segment',
  Parent = 'Parent',
  Currency = 'Currency',
  PlannedAmount = 'Planned Amount',
  ActualAmount = 'Actual Amount',
  Status = 'Status',
  Timeframe = 'Timeframe',
  CreatedDate = 'Created Date',
  UpdatedDate = 'Updated Date',
  InvoiceNumber = 'Invoice Number',
  PONumber = 'PO Number',
  Vendor = 'Vendor',
  // Invoices
  InvoiceName = 'Invoice Name',
  InvoiceNum = 'Invoice #',
  Amount = 'Amount',
  Month = 'Month',
  Submitted = 'Submitted',
  By = 'By'
}

export interface PaginationParams {
  limit: number;
  offset: number;
  page?: number;
}

export interface LocalSpendingPageFilters extends PaginationParams {
  ordering?: string;
  search?: string;
}

export interface BudgetObjectsData {
  goals: Goal[];
  campaigns: LightCampaign[];
  expGroups: LightProgram[];
}

export interface BudgetSegmentsData {
  segments: BudgetSegmentAccess[];
  segmentGroups: SegmentGroup[];
  sharedCostRules: SharedCostRule[];
  allowedSharedCostRules: SharedCostRule[];
}

export interface BudgetHierarchyData {
  objectsData: BudgetObjectsData;
  segmentsData: BudgetSegmentsData;
}

export interface ExpensePageBudgetData {
  budgetHierarchyData: BudgetHierarchyData;
  budgetTimeframes: BudgetTimeframe[];
}

export interface ExpensePageCompanyData {
  glCodes: GLCode[];
  vendors: Vendor[];
}

export interface ExpensePageData {
  companyData: ExpensePageCompanyData;
  budgetData: ExpensePageBudgetData;
}

export interface StatusSelectEvent {
  expenseId: number;
  status: ExpenseAllocationMode;
  setActual?: number;
}

export interface ContextStatusListOptions {
  status: ExpenseAllocationMode;
  planned: number;
  actual: number;
  sourceCurrency: string;
  isTimeframeLocked: boolean;
}

export interface ExpenseStatusSelectOptions extends ContextStatusListOptions {
  expenseId: number;
}

export interface PatchExpenseData {
  id: number;
  body: {
    mode?: ExpenseAllocationMode;
    source_amount?: number;
    source_actual_amount?: number;
    company_budget_alloc?: number;
    company_budget_segment1?: number;
  };
}

export interface TimeframeMapItem {
  name: string;
  locked: boolean;
}

export enum ExpenseUpdateField {
  Segment = 'company_budget_segment1',
  Campaign = 'campaign',
  GlCode = 'gl_code',
  Status = 'mode',
  Timeframe = 'company_budget_alloc',
  Vendor = 'vendor',
  ExpenseGroup = 'program',
  SplitRule = 'split_rule'
}

export const NOT_SPECIFIED_ID = 0;

export enum SidebarActionMessage {
  ParentUpdated = 'Parent updated',
  SegmentUpdated = 'Updated Segment from the Parent',
  NoParentSet = 'Segment updated. Parent set to ‘No Parent’'
}
