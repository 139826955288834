import { ExpenseDO, ExtraExpenseEmailBody } from 'app/shared/types/expense.interface';
import { Metric } from '../components/details-metrics/details-metrics.type';
import {
  BudgetObjectAllocation,
  CampaignAllocation,
  ProgramAllocation
} from 'app/shared/types/budget-object-allocation.interface';
import { ProgramDO } from 'app/shared/types/program.interface';
import { TagMapping } from 'app/shared/types/tag-mapping.interface';
import { BudgetObjectTotals } from 'app/shared/types/budget-object-totals.interface';
import { MetricDO, MetricDataDO, MetricMappingDO, MetricMappingThirdPartyAmountsDO } from 'app/shared/services/backend/metric.service';
import { AttachmentMappingDO } from 'app/shared/types/attachment.interface';
import { DetailsState } from './details-state.interface';
import { TaskDO } from 'app/shared/types/task.interface';
import { CampaignDO } from 'app/shared/types/campaign.interface';
import { GoalDO } from 'app/shared/types/goal.interface';
import { CEGStatus } from '@shared/enums/ceg-status.enum';
import { MetricMappingCalculationDO } from '@shared/services/backend/metric-calculation.service';
import { MetricMilestones } from './metric-milestone.interface';
import { MetricMappingThirdPartyAmounts, MetricSummary } from './metric-mapping-details-state.interface';
import { MetricValueRecords } from './metric-value-records.interface';
import { MetricValueUpdatesData } from './metric-value-update-item.interface';

export type BudgetDataObject = CampaignDO | ProgramDO | ExpenseDO | GoalDO;

export interface BudgetObjectParent {
  id: number;
  type: string;
}

export interface SegmentedObjectDetailsState {
  ownerId: number;
  amount: number;
  segment: { segmentId?: number, sharedCostRuleId?: number }
  // Special flag for the API to decide whether we should sync children segments or not
  spreadSegmentToChildren?: boolean;
}

export interface BudgetObjectDetailsState extends DetailsState {
  budgetId?: number;
  companyId?: number;
  typeId: number;
  name: string;
  expenses?: ExpenseDO[];
  tagMappings?: TagMapping[];
  metricMappings?: Metric[];
  createdBy?: number;
  attachmentMappings: AttachmentMappingDO[];
  created?: string;
  updated?: string;
}

export interface GroupingObject {
  statusTotals: BudgetObjectTotals;
}

export interface AllocatableObject {
  allocations: BudgetObjectAllocation[];
  amountStatus?: CEGStatus;
  currencyCode?: string;
}

export interface GoalDetailsState extends BudgetObjectDetailsState, GroupingObject {
  campaigns: CampaignDO[];
  programs: ProgramDO[];
  metricData: MetricDataDO[];
}

export interface CampaignDetailsState extends BudgetObjectDetailsState, AllocatableObject, SegmentedObjectDetailsState, GroupingObject {
  allocations: CampaignAllocation[];
  startDate: string;
  endDate: string;
  targetAudience?: string;
  messaging?: string;
  campaigns?: CampaignDO[];
  programs?: ProgramDO[];
  mode?: string;
  metricData: MetricDataDO[];
  tasks: TaskDO[];
  goalId?: number;
  parentCampaignId?: number;
  keyMetricId: number;
  lockForIntegrations: boolean;
  externalId?: string;
  source?: BudgetObjectSource;
  glCode?: number;
  vendor?: number;
  vendorName?: string;
  sourceAmount?: number;
  forecastAmount?: number;
  sourceForecastAmount?: number;
  campaign_integrated_source?: boolean;
}

export interface ProgramDetailsState extends BudgetObjectDetailsState, AllocatableObject, SegmentedObjectDetailsState, GroupingObject {
  allocations: ProgramAllocation[];
  glCode: number;
  poNumber: string;
  expenseTotals?: object;
  mode?: string;
  tasks: TaskDO[];
  goalId?: number;
  campaignId?: number;
  spreadGLCodeToChildren?: boolean;
  spreadPONumberToChildren?: boolean;
  externalId?: string;
  vendor?: number;
  vendorName?: string;
  sourceAmount?: number;
  forecastAmount?: number;
  sourceForecastAmount?: number;
  source: BudgetObjectSource;
  startDate?: string;
  endDate?: string;
}

export interface MetricDetailsState extends BudgetObjectDetailsState, AllocatableObject, SegmentedObjectDetailsState, GroupingObject {
  parentId: number;
  metricId: number;
  mappingType: string;
  revenuePerOutcome?: number;
  revenueToProfit?: number;
  metricUnit?: string;
  metricName: string;
  productName?: string;
  startDate: string;
  lastUpdatedDate: string;
  funnelName: string;
  milestones: MetricMilestones;
  thirdPartyAmounts: MetricMappingThirdPartyAmounts;
  ROIRecords: MetricValueRecords<number>;
  CPORecords: MetricValueRecords<number>;
  metricValueRecords: MetricValueRecords<number>;
  metricCalculations: MetricMappingCalculationDO[];
  metricValueUpdates: MetricValueUpdatesData;
  childMetricMappings: MetricMappingDO[];
  currentValue: number;
  targetValue: number;
  summary: MetricSummary;
  isInherited: boolean;
  isKeyMetric: boolean;
  tasks: TaskDO[];
}

export interface ExpenseDetailsState extends BudgetObjectDetailsState, SegmentedObjectDetailsState {
  amount: number;
  actualAmount: number;
  sourceAmount: number;
  sourceActualAmount: number;
  mode: string;
  budgetAllocationId: number;

  glCode: number;
  poNumber: string;
  invoiceNumber: string;
  vendor: number;
  vendorName: string;
  currencyCode: string;
  parentObject?: BudgetObjectParent;
  deliveryDate: string;
  goalId?: number;
  campaignId?: number;
  programId?: number;
  source: BudgetObjectSource;
  relationGroupId?: string;
  externalId?: string;
  isVerified?: boolean;
  recognizedFields?: { [key: string]: string };
  pdfUrl: string;
  bodyEmailContents?: ExtraExpenseEmailBody;
}

export type AllocatableObjectState = CampaignDetailsState | ProgramDetailsState;
export type ObjectDetailsCommonState = ExpenseDetailsState & CampaignDetailsState & ProgramDetailsState & GoalDetailsState & MetricDetailsState;

export type BudgetObjectSource = '' | 'google_ads' | 'plannuh_automation' | 'import' | 'linkedin_ads' | 'facebook_ads' | 'manual_entry' | 'invoice_processing';
export const BudgetObjectSourceLabels = {
  google_ads: 'Google Ads',
  linkedin_ads: 'LinkedIn Ads',
  facebook_ads: 'Facebook Ads',
  plannuh_automation: 'Planful Automation',
  import: 'Import',
  manual_entry: 'Manual Entry',
  invoice_processing: 'Invoice Processing',
};
