import { Injectable, inject } from "@angular/core";
import { CommonService } from "@shared/services/backend/common.service";
import { Observable, map } from "rxjs";

@Injectable()
export class PostAuthService {
  private readonly commonService = inject(CommonService);

  isUserAllowedToProceed(): Observable<boolean> {
    return this.commonService.getFilterCompanies()
      .pipe(
        map((data: any) => {
          if (data.data.length === 0) {
            return false;
          }
          return true;
        })
      );
  }
}
