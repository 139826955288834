import {
  createDateString,
  parseDateString
} from '../../budget-object-details/components/containers/campaign-details/date-operations';
import { BudgetTimeframesType } from '../types/budget.interface';

export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const MONTHS_PER_YEAR = 12;
export const ORDERED_SHORT_MONTHS_NAMES = [ 'jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

export const createMonthToQuarterMapping = (budgetStartDate: Date) => {
  const startMonthIndex = budgetStartDate.getMonth();
  const currentYear = budgetStartDate.getFullYear();
  const orderedMonths = [...ORDERED_SHORT_MONTHS_NAMES].map((name, i) => ({ name, order: i + 1 }));
  const nextYearMonths = orderedMonths.splice(0, startMonthIndex).map((month, i) => (`${currentYear + 1}-${month.order}`));
  const currentYearMonths = orderedMonths.map((month, i) => (`${currentYear}-${month.order}`));
  return [ ...currentYearMonths, ...nextYearMonths ].reduce((res, monthKey, i) => {
    const monthOrder = i + 1;
    if (monthOrder % 3 === 0) {
      const quarterKey = 'Q' + monthOrder / 3;
      res[quarterKey] = monthKey;
    }
    return res;
  }, {})
};

export const getMonthNameFromDate = (dateString: string): string => {
  if (!dateString) {
    return null;
  }
  const startInd = parseDateString(dateString).getMonth();
  return ORDERED_SHORT_MONTHS_NAMES[startInd];
}

export const getStartDateOfCurrentTimeframe = (budgetType: string, budgetFrom: string): string => {
  let timeFrameStartDate = parseDateString(budgetFrom);
  const today = new Date();
  timeFrameStartDate.setDate(1);
  today.setDate(1);
  const budgetStartMonthInd = timeFrameStartDate.getMonth();

  const prepareResponse = (currentTimeFrameStartDate) => {
    if (budgetStartMonthInd === currentTimeFrameStartDate.getMonth()) {
      // it's first timeframe in budget - start import from beginning of fiscal year
      return null;
    }
    return createDateString(currentTimeFrameStartDate);
  }
  if (budgetType === BudgetTimeframesType.Quarter) {
    while (timeFrameStartDate < today) {
      if (addMonthsToDate(timeFrameStartDate, 3) > today) {
        return prepareResponse(timeFrameStartDate);
      }
      timeFrameStartDate = addMonthsToDate(timeFrameStartDate, 3);
    }
  }
  return prepareResponse(today);
}

export const isLeapYear = (year: number): boolean => {
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
};

export const getDaysInMonth = (year: number, month: number): number => {
  return [31, (isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

export const addMonthsToDate = (_date: Date, value: number): Date => {
  const date = new Date(_date);
  const currentDate = date.getDate();
  const resultMonth = date.getMonth() + value;

  date.setDate(1);
  date.setMonth(resultMonth);

  const daysInMonth = getDaysInMonth(date.getFullYear(), resultMonth % MONTHS_PER_YEAR);
  date.setDate(Math.min(currentDate, daysInMonth));

  return date;
};

export const addDaysToDate = (date: Date, daysToAdd): Date => {
  const updDate = new Date(date);
  updDate.setDate(updDate.getDate() + daysToAdd);
  return updDate
};

export const getDiffInDays = (startDate: Date, endDate: Date, isAbsolute = true): number => {
  const diffInTime = endDate.getTime() - startDate.getTime();
  const diffSign = Math.sign(diffInTime);
  const diffInDays = Math.floor(Math.abs(diffInTime / ONE_DAY_IN_MS));

  return isAbsolute ? diffInDays : diffSign * diffInDays;
};

export const getDiffInMonth = (startDate: Date, endDate: Date): number => {
  const diffInYears = endDate.getFullYear() - startDate.getFullYear();

  return (endDate.getMonth() + diffInYears * MONTHS_PER_YEAR) - startDate.getMonth();
};

export const getTodaysDate = (todayDate?: Date): Date => {
  const today = todayDate || new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return today;
};

export const getTodaysDateUTC = (): Date => {
  const today = new Date();
  today.setUTCHours(0);
  today.setUTCMinutes(0);
  today.setUTCSeconds(0);
  today.setUTCMilliseconds(0);
  return today;
};

export const isLastDayInMonth = (date: Date | string) => {
  const dt: Date = typeof date === 'string' ? parseDateString(date) : date;
  return new Date(dt.getTime() + ONE_DAY_IN_MS).getDate() === 1;
};

export const getBudgetToDateString = (dateFrom: Date): string => {
  const datePlusYear = addMonthsToDate(dateFrom, MONTHS_PER_YEAR);
  const budgetToDate = addDaysToDate(datePlusYear, -1);
  return createDateString(budgetToDate);
};

// Datepicker today's date definition
export const isTodayDateCell = (cellDate: Date, todayDate: Date, view: 'multi-year' | 'year' | 'month'): boolean => {
  const isYearMatched = cellDate.getFullYear() === todayDate.getFullYear();
  const isMonthMatched = cellDate.getMonth() === todayDate.getMonth();
  const isDateMatched = cellDate.getDate() === todayDate.getDate();

  switch (view) {
    case 'multi-year':
      return isYearMatched;
    case 'year':
      return isYearMatched && isMonthMatched;
    case 'month':
      return isYearMatched && isMonthMatched && isDateMatched;
  }
};

export const formatDateStringInShortFormat = (dateString: string): string => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'short', 
    day: 'numeric', 
    year: 'numeric'
  });

  return formattedDate;
}
