import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { getDiff } from 'recursive-diff';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { BudgetSegment, BudgetSegmentAmount, BudgetSegmentAmountDO, BudgetSegmentDO } from 'app/shared/types/segment.interface';
import { DIALOG_ACTION_TYPE, DialogAction, DialogContext, DuplicateBudgetContext } from 'app/shared/types/dialog-context.interface';
import { UserProfileDO } from 'app/shared/types/user-profile.interface';
import { CompanyUserDO, CompanyUserStatus } from 'app/shared/types/company-user-do.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetStatus } from 'app/shared/types/budget-status.type';
import { createDeepCopy, generateGUID } from 'app/shared/utils/common.utils';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { BudgetService } from 'app/shared/services/backend/budget.service';
import { BudgetSegmentService } from 'app/shared/services/backend/budget-segment.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { UserService } from 'app/shared/services/backend/user.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { CompanyUserService } from 'app/shared/services/backend/company-user.service';
import { BudgetObjectDialogService } from 'app/shared/services/budget-object-dialog.service';
import { BudgetAllocationService } from 'app/shared/services/backend/budget-allocation.service';
import { IncreaseBudgetAmountDialogComponent } from '../increase-budget-amount-dialog/increase-budget-amount-dialog.component';
import { dialogConfig as increaseBudgetAmountDialogConfig } from '../increase-budget-amount-dialog/dialog-config';
import { BudgetEditModalComponent } from '../budget-edit-modal/budget-edit-modal.component';
import { BudgetEditDialogData, BudgetEditDialogFormData } from '../budget-edit-modal/budget-edit-modal.types';
import { OwnerSelectOption, UserOwner, ActionToConfirm, UnsavedChanges, SegmentOperation } from './budget-settings-page.type';
import * as utils from './budget-settings-page.utils';
import { adaptBudgetPermission, createUsersProfilesMap, getInitialsFromName } from 'app/shared/utils/users.utils';
import { createDateString, parseDateString } from 'app/budget-object-details/components/containers/campaign-details/date-operations';
import { BudgetTableValidationService } from '../../services/budget-table-validation.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { getBudgetToDateString } from 'app/shared/utils/date.utils';
import { BudgetsManagingService } from 'app/budget-settings/services/budgets-managing.service';
import { BudgetTableService } from '../../services/budget-table.service'
import { BudgetTableHelpers } from '../../services/budget-table-helpers.service'
import { Currency } from 'app/shared/types/currency.interface';
import { CompanyDO } from 'app/shared/types/company.interface';
import { IncreaseBudgetAmountDialogContext } from '../increase-budget-amount-dialog/increase-budget-amount-dialog.type';
import { ChurnZeroService, EventName } from 'app/shared/services/churn-zero.service';
import { ExpenseTotalsBySegments } from 'app/shared/types/expense-totals-by-segments.type';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { Configuration } from 'app/app.constants';
import { SegmentGroup, SegmentGroupDO } from 'app/shared/types/segment-group.interface';
import { SegmentGroupService } from 'app/shared/services/backend/segment-group.service';
import { BudgetTableRecord } from '../budget-table/budget-table.types';
import { BudgetTableSelectedRecords } from '../../types/budget-table-selection.types';
import { BudgetTableRecordInteractionsService } from '../../services/budget-table-record-interactions.service';
import { BudgetPermission } from 'app/shared/types/user-permission.type';
import { DeepPartial } from 'app/shared/types/deep-partial.type';
import { AdsIntegrations, MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { Integration } from 'app/metric-integrations/types/metrics-provider-data-service.types';
import { MetricIntegrationsProviderService } from 'app/metric-integrations/services/metric-integrations-provider.service';
import { MetricsProviderWithImplicitMappingsDataService } from 'app/metric-integrations/services/metrics-provider-with-implicit-mappings-data.service';
import { CampaignService } from 'app/shared/services/backend/campaign.service';
import { MetricIntegrations } from 'app/metric-integrations/types/metric-integrations-status.interface';
import { EXPORT_TYPES } from 'app/dashboard/export-data.service';
import { UserDO } from '@shared/types/user-do.interface';
import { BudgetDataSpecification } from '../../types/budget-data-specification.interface';

const { messages } = utils;
const responseStatusSegmentUsed = 409;

@Injectable()
export class BudgetSettingsPageService implements OnDestroy {
  private destroy$ = new Subject<void>();

  public company: CompanyDO;
  public currentUserId: number;
  public budget: Budget;
  public accountOwner: { id: number; name: string };
  public budgetOwner: { id: number; name: string };
  public budgetCurrency: Currency;
  public budgetsCount = 0;
  public userIsBudgetOwner = false;

  private readonly ownerSelectOptions = new BehaviorSubject<OwnerSelectOption[]>([]);
  private readonly ownersList = new BehaviorSubject<UserOwner[]>([]);
  private readonly onSave = new Subject<void>();
  private readonly onSaveCompleteTrigger = new Subject<void>();
  private readonly onDataLoading = new Subject<void>();
  public readonly ownersList$ = this.ownersList.asObservable();
  public readonly ownerSelectOptions$ = this.ownerSelectOptions.asObservable();
  public readonly onSave$ = this.onSave.asObservable();
  public readonly onDataLoading$ = this.onDataLoading.asObservable();
  public readonly onSaveCompleteTrigger$ = this.onSaveCompleteTrigger.asObservable();

  public selectedRecords: BudgetTableSelectedRecords = {
    segments: [],
    groups: []
  };
  public budgetAllocations: BudgetTimeframe[] = [];
  public expenseTotalsBySegments: ExpenseTotalsBySegments = {};

  private savedSegmentsById: { [id: number]: BudgetSegment };
  private savedGroupsById: { [id: number]: SegmentGroup };
  private unsavedChanges = new BehaviorSubject<UnsavedChanges>(utils.defaultUnsavedChanges);
  private tableDataLoadingState = {
    segments: false,
    groups: false
  };
  private budgetDataSpecification: BudgetDataSpecification = utils.BudgetDataSpecification;

  public hasUnsavedChanges = false;
  public showTodayDatePicker = false;
  private _fixedDateEnabled: boolean;
  private _todayFixedDate: Date;
  private isPlanfulUser: boolean;
  isCegBudget: boolean;

  constructor(
    private readonly companyDataService: CompanyDataService,
    private readonly budgetDataService: BudgetDataService,
    private readonly userService: UserService,
    private readonly utilityService: UtilityService,
    private readonly companyUserService: CompanyUserService,
    private readonly budgetService: BudgetService,
    private readonly matDialog: MatDialog,
    private readonly budgetSegmentService: BudgetSegmentService,
    private readonly segmentGroupService: SegmentGroupService,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly budgetAllocationService: BudgetAllocationService,
    private readonly budgetTableValidationService: BudgetTableValidationService,
    private readonly budgetTableRecordInteractionsService: BudgetTableRecordInteractionsService,
    private readonly userManager: UserManager,
    private readonly budgetsManagingService: BudgetsManagingService,
    private readonly budgetTableService: BudgetTableService,
    private readonly churnZeroService: ChurnZeroService,
    private readonly expensesService: ExpensesService,
    private readonly configuration: Configuration,
    private readonly router: Router,
    private readonly metricIntegrationsProvider: MetricIntegrationsProviderService,
    private readonly campaignService: CampaignService
  ) {
    this.companyDataService.selectedCompanyDO$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null)
      )
      .subscribe(company => this.onSelectedCompanyChanged(company));

    this.budgetDataService.selectedBudget$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (budget: Budget) => this.onSelectedBudgetChanged(budget),
        (error) => this.utilityService.handleError(error)
      );

    this.budgetDataService.companyBudgetList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((budgets: Budget[]) => this.budgetsCount = budgets.length);

    this.budgetDataService.segmentGroupList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((groups: SegmentGroup[]) => this.processSegmentGroups(groups));

    this.userManager.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: UserDO) => {
        this.currentUserId = Number(user?.id);
        this.showTodayDatePicker = this.isPlanfulUser = user?.email.includes('@planful.com');
      });

    this.budgetDataService.timeframeList$
      .pipe(takeUntil(this.destroy$))
      .subscribe(allocations => this.budgetAllocations = createDeepCopy(allocations));

    this.unsavedChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(unsavedChanges =>
        this.setUnsavedChangesFlag(unsavedChanges)
      )
  }

  private setUnsavedChangesFlag(unsavedChanges: UnsavedChanges) {
    this.hasUnsavedChanges = Object.keys(unsavedChanges).some(
      key => typeof unsavedChanges[key] === 'number'
        ? unsavedChanges[key] > 0
        : Object.keys(unsavedChanges[key]).length > 0
    )
  }

  private resetLoadingState() {
    this.tableDataLoadingState = {
      segments: false,
      groups: false
    };
  }

  public get todayFixedDateEnabled(): boolean {
    return this._fixedDateEnabled;
  }

  public get todayFixedDate(): Date {
    return this._todayFixedDate;
  }

  private onSelectedBudgetChanged(budget: Budget) {
    this.isCegBudget = budget?.new_campaigns_programs_structure;
    if (budget && budget.id !== this.budget?.id) {
      this._fixedDateEnabled = !!budget.fixed_date;
      this._todayFixedDate = budget.fixed_date ? parseDateString(budget.fixed_date) : new Date();

      this.resetLoadingState();
      this.budgetTableRecordInteractionsService.resetToggling();
      this.onDataLoading.next();
      this.budgetTableService.currentBudget = budget;
      this.loadExpenseTotals(budget.id);
      this.loadSegmentsList(budget.id);
      this.loadBudgetObjects(budget.id);
      this.unsavedChanges.next(utils.defaultUnsavedChanges);
    }
    this.budget = budget;
    this.setBudgetOwner();
  }

  private onSelectedCompanyChanged(companyDO: CompanyDO) {
    this.company = companyDO;
    this.companyDataService.loadCompanyData(companyDO.id);
    this.initBudgetSettingsPage();
    this.budgetCurrency = {
      code: companyDO.currency,
      name: companyDO.currency,
      symbol: companyDO.currency_symbol
    };
  }

  private initBudgetSettingsPage() {
    const usersProfiles$ = this.userService.getUsersProfiles();
    const companyUsers$ = this.companyDataService.companyUsersDO$.pipe(
      filter(data => data != null),
      takeUntil(this.destroy$)
    );
    const companyBudgets$ = this.budgetDataService.companyBudgetList$.pipe(
      take(1)
    );

    this.utilityService.showLoading(true);
    combineLatest([
      usersProfiles$,
      companyUsers$,
      companyBudgets$
    ])
    .subscribe(
      ([usersProfiles, companyUsers, companyBudgets]) => {
        this.processLoadedUsers(usersProfiles, companyUsers);
        if (!companyBudgets?.length && !this.matDialog.openDialogs.length) {
          this.openBudgetSettingsModal(); // If no budgets - init budget creation
        }
        this.utilityService.showLoading(false);
      },
      () => this.utilityService.handleError({ message: messages.loadUsersFailure })
    );
  }

  private processLoadedUsers(usersProfiles: UserProfileDO[], companyUsers: CompanyUserDO[]) {
    const ownersList = this.createOwnersList(usersProfiles, companyUsers);

    this.ownersList.next(ownersList);
    this.refreshOwnerSelectOptions();
    this.setAccountOwner(usersProfiles, companyUsers);
    this.setBudgetOwner();
  }

  private initTableState() {
    if (this.tableDataLoadingState.groups && this.tableDataLoadingState.segments) {
      this.budgetTableService.initState();
      this.savedSegmentsById = BudgetTableHelpers.deepCopyAsMap(this.budgetTableService.segmentsList);
      this.savedGroupsById = BudgetTableHelpers.deepCopyAsMap(this.budgetTableService.groupsList);
    }
  }

  public loadSegmentsList(budgetId) {
    this.utilityService.showLoading(true);
    this.budgetSegmentService.getBudgetSegments(budgetId)
      .pipe(
        map(segments => this.adaptBudgetSegments(segments)),
      ).subscribe(segments => {
        this.budgetTableService.setSegmentsList(segments);
        this.utilityService.showLoading(false);
        this.tableDataLoadingState.segments = true;
        this.initTableState();
      })
  }

  private loadExpenseTotals(budgetId: number) {
    this.expensesService.getTotalsBySegments(budgetId)
      .pipe(
        catchError((error) => {
          this.expenseTotalsBySegments = {};
          throw error;
        }),
        tap((totals) => {
          this.expenseTotalsBySegments = totals;
          this.budgetTableService.rawExpenseTotals = totals;
          this.budgetTableService.prepareExpenseTotals();
        })
      )
      .subscribe({
        error: () => this.utilityService.handleError({ message: messages.loadExpenseTotalsFailure })
      });
  }

  private loadBudgetObjects(budgetId: number) {
    if (!budgetId || !this.company?.id) {
      return;
    }

    this.budgetDataService.loadLightCampaigns(
      this.company?.id,
      budgetId,
      this.configuration.campaignStatusNames.active,
      error => this.utilityService.handleError(error)
    );
    this.budgetDataService.loadLightPrograms(
      this.company?.id,
      budgetId,
      this.configuration.programStatusNames.active,
      error => this.utilityService.handleError(error)
    );
  }

  private setAccountOwner(usersProfiles: UserProfileDO[], companyUsers: CompanyUserDO[]) {
    const accountOwner = companyUsers.find(user => user.is_account_owner);
    if (accountOwner) {
      const accountOwnerProfile = usersProfiles.find(user => user.user === accountOwner.user);
      this.accountOwner = { id: accountOwner.user, name: accountOwnerProfile.name };
    }
  }

  private setBudgetOwner() {
    const budgetOwnerId = this.budget?.owner;
    const availableOwners = this.ownersList.getValue();
    if (budgetOwnerId && availableOwners.length) {
      const budgetOwner = availableOwners.find(user => user.id === budgetOwnerId);
      if (budgetOwner) {
        this.budgetOwner = { id: budgetOwner.id, name: budgetOwner.name };
        this.userIsBudgetOwner = this.budgetOwner.id === this.currentUserId;
      }
    }
  }

  public refreshOwnerSelectOptions(segmentId?: number) {
    const updOwnerSelectOpt: OwnerSelectOption[] = this.ownersList.getValue()
      .filter(item => item.status != CompanyUserStatus.Disabled
        && item.status != CompanyUserStatus.Deleted).map(owner =>
          this.createOwnerSelectOptions(owner, this.budget?.id, segmentId)
        );
    this.ownerSelectOptions.next(updOwnerSelectOpt);
  }

  public addNewSegment() {
    this.budgetTableService.addNewSegment(this.budgetAllocations);
    this.refreshCreatedSegmentsCount();
  }

  public addGroupSegment(group: BudgetTableRecord) {
    this.budgetTableService.addGroupSegment(this.budgetAllocations, group);
    this.refreshCreatedSegmentsCount();
  }

  public updateSegment(segment: BudgetSegment) {
    if (segment && segment.id) {
      const changed = this.checkSegmentsDataDiff(segment, this.savedSegmentsById[segment.id]);
      const unsavedChanges = this.unsavedChanges.getValue();
      const changedSegmentsData = { ...unsavedChanges.changedSegmentsData };
      if (changed) {
        changedSegmentsData[segment.id] = SegmentOperation.Updated;
      } else {
        delete changedSegmentsData[segment.id];
      }
      this.unsavedChanges.next({ ...unsavedChanges, changedSegmentsData });
    }
  }

  public updateSegmentAmounts(segment: BudgetSegment) {
    if (segment && segment.id) {
      const originalSegment = this.savedSegmentsById[segment.id];
      const currentSegment = segment;
  
      const unsavedChanges = this.unsavedChanges.getValue();
      const changedSegmentsAmounts = { ...unsavedChanges.changedSegmentsAmounts };
  
      const updatedAmounts = [];
  
      for (let i = 0; i < currentSegment.amounts.length; i++) {
        const current = currentSegment.amounts[i];
        const original = originalSegment.amounts?.find(a => a.id === current.id);
  
        const changedAmount = current.amount !== original?.amount;
        const changedForecast = current.forecastAmount !== original?.forecastAmount;
  
        if (changedAmount || changedForecast) {
          updatedAmounts.push({
            id: current.id,
            amount: changedAmount ? current.amount : original?.amount,
            forecast_amount: changedForecast ? current.forecastAmount : original?.forecastAmount
          });
        }
      }
  
      if (updatedAmounts.length > 0) {
        changedSegmentsAmounts[segment.id] = updatedAmounts;
      } else {
        delete changedSegmentsAmounts[segment.id];
      }
  
      this.unsavedChanges.next({
        ...unsavedChanges,
        changedSegmentsAmounts,
      });
    }
  }  

  public removeSelectedSegments() {
    this.removeSegmentsFromList(this.selectedRecords);
  }

  public removeSegmentsFromList(selectedRecords: BudgetTableSelectedRecords) {
    const { restSegments = [], deletedSegments = [] } = this.budgetTableService.removeSegments(selectedRecords.segments);
    if (restSegments?.length) {
      this.onDeleteSegments(deletedSegments);
    } else {
      this.utilityService.showToast({
        Title: '', Message: messages.lastSegmentWarning, Type: 'error'
      });
    }
  }

  public toggleAllocationLockState(allocation: BudgetTimeframe) {
    const unsavedChanges = this.unsavedChanges.getValue();
    const allocationsLockState = { ...unsavedChanges.allocationsLockState };
    if (allocationsLockState.hasOwnProperty(allocation.id)) {
      delete allocationsLockState[allocation.id];
    } else {
      allocationsLockState[allocation.id] = allocation.locked;
    }
    this.unsavedChanges.next({
      ...unsavedChanges, allocationsLockState
    })
  }

  private onDeleteSegments(deletedSegmentsList: BudgetSegment[]) {
    const unsavedChanges = this.unsavedChanges.getValue();
    const deletedSegments = { ...unsavedChanges.deletedSegments };
    deletedSegmentsList
      .filter(segment => segment.id)
      .forEach(segment => deletedSegments[segment.id] = segment);
    this.unsavedChanges.next({
      ...unsavedChanges,
      deletedSegments,
      createdSegmentsCount: this.budgetTableService.segmentsList.filter(segment => !segment.id).length,
    });

    this.refreshCreatedGroupsCount();
  }

  public updateGroup(group: SegmentGroup) {
    if (group && group.id) {
      const changed = this.checkGroupsDataDiff(group, this.savedGroupsById[group.id]);
      const unsavedChanges = this.unsavedChanges.getValue();
      const changedGroupsData = { ...unsavedChanges.changedGroupsData };
      if (changed) {
        changedGroupsData[group.id] = true;
      } else {
        delete changedGroupsData[group.id];
      }
      this.unsavedChanges.next({ ...unsavedChanges, changedGroupsData });
    }
  }

  public groupSelectedSegments() {
    this.groupSegments(this.selectedRecords);
  }

  public groupSegments(selectedRecords: BudgetTableSelectedRecords) {
    const { segments } = this.budgetTableService.groupSegments(selectedRecords.segments);
    if (segments?.length) {
      segments.forEach(segment => this.updateSegment(segment));
    }
    this.refreshCreatedGroupsCount();
  }

  public ungroupSelectedSegments() {
    this.ungroupSegments(this.selectedRecords);
  }

  public ungroupSegments(selectedRecords: BudgetTableSelectedRecords) {
    const { segments } = this.budgetTableService.ungroupSegments(selectedRecords.segments);
    if (segments?.length) {
      segments.forEach(segment => this.updateSegment(segment));
    }

    this.refreshCreatedGroupsCount();
  }

  public moveSelectedSegments(targetGroup: SegmentGroup) {
    this.moveSegments({
      selectedRecords: this.selectedRecords,
      targetGroup
    });
  }

  public moveSegments(payload: { selectedRecords: BudgetTableSelectedRecords; targetGroup: SegmentGroup }) {
    const { selectedRecords, targetGroup } = payload;
    const { segments } = this.budgetTableService.moveSegments(selectedRecords.segments, targetGroup);
    if (segments?.length) {
      segments.forEach(segment => this.updateSegment(segment));
    }
  }

  public duplicateSelectedGroups() {
    this.duplicateGroups(this.selectedRecords);
  }

  public duplicateGroups(selectedRecords: BudgetTableSelectedRecords) {
    this.budgetTableService.duplicateGroups(selectedRecords.groups);
    this.refreshCreatedSegmentsCount();
    this.refreshCreatedGroupsCount();
  }

  public saveCreatedBudget(budgetData: Partial<Budget>) {
    const payload = {
      ...budgetData,
      company: this.company?.id,
      create_by: this.currentUserId,
      suppress_timeframe_allocations: this.company?.default_for_suppress_timeframe_allocations
    };

    this.utilityService.showLoading(true);
    this.budgetService.createBudget(payload)
      .pipe(
        switchMap(createdBudget =>
          this.budgetsManagingService.updateExchangeRatesAfterAddingOrUpdatingBudget(
            this.company?.id, this.budgetCurrency.code
          ).pipe(map(() => createdBudget))
        )
      )
      .subscribe(
        createdBudget => {
          delete createdBudget.allocations;
          this.budgetDataService.onBudgetCreated(createdBudget);
          this.setBudgetOwner();
          this.utilityService.showToast({
            Title: '', Message: messages.createBudgetSuccess, Type: 'success'
          });
        },
        error => this.utilityService.handleError(error),
        () => this.utilityService.showLoading(false)
      )
  }

  public updateBudgetStatus(status: BudgetStatus) {
    this.updateBudget(this.budget.id, { status }).subscribe(
      () => {
        this.budget = { ...this.budget, status };
        this.utilityService.showToast({
          Title: '', Message: messages.updateBudgetStatusSuccess, Type: 'success'
        });
      },
      error => this.utilityService.handleError(error)
    )
  }

  public updateBudgetData(budgetData: Partial<Budget>) {
    const shouldReloadTimeframes = this.budget.budget_from !== budgetData.budget_from;
    const budgetSnapshot = this.budgetDataService.budgetListSnapshot.find((budget) => budget.id === budgetData.id);
    const isCEGStructureChanged = budgetData.new_campaigns_programs_structure !== budgetSnapshot.new_campaigns_programs_structure;
    this.updateBudget(this.budget.id, budgetData)
      .pipe(
        switchMap(() => {
          if (isCEGStructureChanged) {
            return budgetData.new_campaigns_programs_structure
              ? this.budgetService.newCEGStructureOptIn(budgetData.id)
              : this.budgetService.newCEGStructureOptOut(budgetData.id)
          }
          return of(null);
        }),
        switchMap(() =>
          this.budgetsManagingService.updateExchangeRatesAfterAddingOrUpdatingBudget(
            this.company?.id, this.budgetCurrency.code
          )
        )
      )
      .subscribe(
        () => {
          if (shouldReloadTimeframes) {
            this.budgetDataService.loadBudgetTimeframes(this.budget.id);
          }
          this.setBudgetOwner();
          this.utilityService.showToast({
            Title: '', Message: messages.updateBudgetSuccess, Type: 'success'
          });
          this.churnZeroService.trackEvent(EventName.BudgetUpdate);
        },
        error => this.utilityService.handleError(error)
      )
  }

  private updateBudget(budgetId: number, params) {
    return this.budgetService.updateBudget(budgetId, params)
      .pipe(tap((updatedBudget) =>
        this.budgetDataService.refreshBudgetInList(updatedBudget)
      ))
  }

  public removeActiveIntegrations() {
    this.getIntegrationsAndCampaignsData(this.budget.id).pipe(
      switchMap((integrationsData: [MetricIntegrations, number[]]) => this.removeBudgetIntegrations(this.budget.id, integrationsData)),
      catchError(error => {
        this.utilityService.handleError(error);
        return throwError(error);
      })
    ).subscribe();
  }

  public deleteBudget() {
    this.confirmAction(ActionToConfirm.DeleteBudget, () => {
      if (this.budgetsCount === 1) {
        this.utilityService.showToast({
          Title: '', Message: messages.lastBudgetWarning, Type: 'error'
        });
        return;
      }
      this.undoChanges();

      const budgetId = this.budget.id;
      this.onBudgetRemoved(budgetId);
      this.utilityService.showToast({ Title: '', Message: messages.deleteBudgetStart });

      forkJoin([
        this.getIntegrationsAndCampaignsData(budgetId),
        this.createBudgetSnapshot(budgetId)
      ]).pipe(
          switchMap(([integrationsData, _]) => this.budgetService.deleteBudget(budgetId).pipe(map(() => integrationsData))),
          switchMap((integrationsData: [MetricIntegrations, number[]]) =>
            forkJoin([
              this.budgetsManagingService.updateExchangeRatesAfterRemovingBudget(this.company?.id),
              this.removeBudgetIntegrations(budgetId, integrationsData)
            ])
          ),
          catchError(error => {
            this.utilityService.handleError(error);
            return throwError(error);
          })
        ).subscribe(() => {
        this.utilityService.showToast({ Title: '', Message: messages.deleteBudgetSuccess });
      });
    });
  }

  private getIntegrationsAndCampaignsData(budgetId: number): Observable<(MetricIntegrations | number[])[]> {
    return this.companyDataService.metricIntegrations$
      .pipe(
        filter((integrationsObj: MetricIntegrations) => !Object.values(integrationsObj).some(val => val === null)),
        switchMap(integrations => this.getCampaignsForRemoval$(budgetId, integrations)
          .pipe(map((campaignIds: number[]) => [integrations, campaignIds]))),
        take(1)
      )
  }

  private createBudgetSnapshot(budgetId: number): Observable<any> {
    const params = {
      file_format: EXPORT_TYPES.JSON,
      obfuscated: false,
      save_to_s3: true
    };
    return this.budgetService.exportBudget(budgetId, params).pipe(
      catchError(() => of(null))
    );
  }

  private onBudgetRemoved(budgetId: number) {
    this.budgetDataService.removeBudgetFromList(budgetId);
    const budgetToSelect = this.budgetDataService.budgetListSnapshot[0];
    this.budgetDataService.selectBudget(budgetToSelect.id);
    this.budgetDataService.selectedBudgetStorage.remove(this.currentUserId, budgetToSelect.id);
  }

  private getCampaignsForRemoval$(budgetId: number, integrations: MetricIntegrations): Observable<number[]> {
    const noAdsIntegrationsMissing =
      Object.keys(integrations)
        .filter(integrationName => integrations[integrationName].length > 0)
        .every(integrationName => AdsIntegrations.includes(integrationName as MetricIntegrationName));

    // All integrations are Ads integrations - no need to fetch the budget campaigns
    if (noAdsIntegrationsMissing) {
      return of(null);
    }
    const params = {
      'company': this.company.id,
      'budget': budgetId,
      'status': this.configuration.campaignStatusNames.active
    };
    return this.campaignService.getCampaigns(params).pipe(
      map(campaigns => campaigns.map(campaign => campaign.id))
    );
  }

  public undoChanges() {
    this.onDataLoading.next();
    this.unsavedChanges.next({ ...utils.defaultUnsavedChanges });
    setTimeout(() => {
      this.budgetAllocations = createDeepCopy(this.budgetDataService.timeframesSnapshot);
      this.budgetTableService.setSegmentsList(createDeepCopy(Object.keys(this.savedSegmentsById).map(id => this.savedSegmentsById[id])));
      this.budgetTableService.setGroupsList(createDeepCopy(Object.keys(this.savedGroupsById).map(id => this.savedGroupsById[id])));
      this.initTableState();
    })
  }

  public saveChanges(options: { onSuccess?: Function; onError?: Function } = {}) {
    this.onSave.next();
    if (!this.budgetTableValidationService.isFormDataValid() || !this.hasUnsavedChanges) {
      return;
    }
    const { onSuccess, onError } = options;

    this.utilityService.showLoading(true);
    this.deleteSegmentsData()
      .pipe(
        switchMap(() => this.saveCreatedGroups()),
        switchMap(() => this.saveCreatedSegmentsWithAmounts()),
        switchMap(() => this.saveUpdatedSegmentsData()),
        switchMap(() => this.saveUpdatedSegmentsAmounts()),
        switchMap(() => this.saveUpdatedBudgetAllocations()),
        switchMap(() => this.saveUpdatedGroupsData()),
        switchMap(() => this.removeEmptyGroups()),
        switchMap(() => this.increaseBudgetAmount(this.budgetTableService.remainingBudget)),
        tap(() => this.onSaveCompleteTrigger.next())
      )
      .subscribe(
        () => {
          this.utilityService.showLoading(false);
          this.utilityService.showToast({
            Title: '', Message: messages.updateDataSuccess, Type: 'success'
          });
          this.resetLoadingState();
          this.loadSegmentsList(this.budget.id);
          this.budgetDataService.loadAvailableBudgetSegments(this.budget.id);
          this.budgetDataService.loadBudgetTimeframes(this.budget.id);
          this.budgetDataService.loadSegmentGroups(this.budget.id);
          this.churnZeroService.trackEvent(EventName.BudgetUpdate);
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        },
        err => {
          this.utilityService.showLoading(false);
          this.utilityService.showToast({ Title: '', Message: err, Type: 'error' });
          if (typeof onError === 'function') {
            onError();
          }
        }
      )
  }

  private deleteSegmentsData(): Observable<any> {
    const unsavedChanges = this.unsavedChanges.getValue();
    const deletedSegments = unsavedChanges.deletedSegments;
    const changedSegmentsAmounts = { ...unsavedChanges.changedSegmentsAmounts };
    const changedSegmentsData = { ...unsavedChanges.changedSegmentsData };

    const requests =
      Object.entries(deletedSegments).map(
        ([segmentId, segment]) =>
          this.budgetSegmentService.deleteBudgetSegment(Number(segmentId)).pipe(
            catchError(error => {
              console.log(`Failed to delete the segment: ${segment.name}(${segmentId})`);
              return of({ segment, error });
            }),
            tap((res) => {
              if (!res?.error) {
                Reflect.deleteProperty(changedSegmentsAmounts, segmentId);
                Reflect.deleteProperty(changedSegmentsData, segmentId);
                Reflect.deleteProperty(this.savedSegmentsById, segmentId);
              }
            })
          )
      );
    if (requests?.length) {
      return forkJoin(requests).pipe(
        tap(() => {
          this.unsavedChanges.next({
            ...unsavedChanges,
            deletedSegments: {},
            changedSegmentsAmounts,
            changedSegmentsData
          })
        }),
        switchMap(results => {
          const failed = (results || []).filter(res => res?.error);
          const failedAsUsed = failed.filter(res => res.error.status === responseStatusSegmentUsed);
          const failedAsUsedSegmentNames = failedAsUsed.map(res => res.segment.name).join(', ');
          const explanation =
            failedAsUsedSegmentNames.length ?
              `Please make sure you have removed all associations to campaigns, expense groups, or expenses to the following segment(s): ${failedAsUsedSegmentNames}` :
              '';

          if (failed.length) {
            const failedSegments = failed.map(res => res.segment);
            this.budgetTableService.restoreRemovedSegments(failedSegments);
          }

          return failed.length ?
            throwError(`Failed to delete the segment(s). ${failedAsUsed.length ? explanation : ''}`) :
            of(results);
        })
      )
    } else {
      return of(null)
    }
  }

  private saveCreatedSegmentsWithAmounts(): Observable<any> {
    const { createdSegmentsCount } = this.unsavedChanges.getValue();
    if (createdSegmentsCount > 0) {
      const createdSegments = this.budgetTableService.segmentsList.filter(segment => !segment.id);
      const createdSegmentsPayload: DeepPartial<BudgetSegmentDO>[] = createdSegments.map(
        segment => ({
          name: segment.name,
          budget: segment.budget,
          owner: segment.owner,
          projected_amount: segment.projectedAmount,
          segment_group: segment.segmentGroup,
          amounts: segment.amounts.map(segmentAmount => ({
            company_budget_alloc: segmentAmount.budgetAllocationId,
            amount: segmentAmount.amount,
            forecast_amount: segmentAmount.forecastAmount
          }))
        })
      );

      return this.budgetSegmentService.createListOfSegments(createdSegmentsPayload)
        .pipe(
          tap(() => this.refreshUnsavedChanges('createdSegmentsCount', 0)),
          catchError(error => this.onSaveRequestError(error, messages.createSegmentsFailure)),
        )
    } else {
      return of(null)
    }
  }

  private saveCreatedGroups(): Observable<any> {
    const { createdGroupsCount } = this.unsavedChanges.getValue();
    if (createdGroupsCount > 0) {
      const createdGroups = this.budgetTableService.groupsList.filter(group => !group.id);

      return forkJoin(createdGroups.map(createdGroup => {
        const payload: Partial<SegmentGroupDO> = {
          name: createdGroup.name,
          budget: createdGroup.budget,
          owner: createdGroup.owner,
        };

        return this.segmentGroupService.createSegmentGroup(payload)
          .pipe(
            tap(createdGroupResult => {
              const targetGroup = this.budgetTableService.getGroupByKey(createdGroup.key);
              if (targetGroup) {
                targetGroup.id = createdGroupResult.id;
              }
              this.budgetTableService.segmentsList.forEach(segment => {
                if (segment.segmentGroupKey === createdGroup.key) {
                  segment.segmentGroup = createdGroupResult.id;
                }
              });
            })
          )
      })).pipe(
        tap(() => this.refreshUnsavedChanges('createdGroupsCount', 0)),
        catchError(error => this.onSaveRequestError(error, messages.createGroupsFailure)),
      );
    } else {
      return of(null)
    }
  }

  private onSaveRequestError(error: any, message: string): Observable<never> {
    console.log(message + ' ' + (error?.message || ''));
    return throwError(message);
  }

  private saveUpdatedSegmentsData(): Observable<any> {
    const { changedSegmentsData } = this.unsavedChanges.getValue();
    const requests = this.budgetTableService.segmentsList
      .filter(segment => changedSegmentsData[segment.id])
      .map(segment => this.budgetSegmentService.updateBudgetSegment(segment.id,
        {
          name: segment.name,
          owner: segment.owner,
          projected_amount: segment.projectedAmount,
          segment_group: segment.segmentGroup
        }
      ));
    return requests?.length ?
      forkJoin(requests).pipe(
        tap(() => this.refreshUnsavedChanges('changedSegmentsData', {})),
        catchError(error => this.onSaveRequestError(error, messages.updateSegmentsFailure))
      ) :
      of(null);
  }

  private saveUpdatedSegmentsAmounts(): Observable<any> {
    const { changedSegmentsAmounts } = this.unsavedChanges.getValue();
    const segmentsIds = Object.keys(changedSegmentsAmounts);
    if (segmentsIds.length) {
      const updatedAmountsPayload = segmentsIds.reduce((store, segmentId) => {
        return [ ...store, ...changedSegmentsAmounts[segmentId] ];
      }, []);
      return this.budgetSegmentService.updateBudgetSegmentAmounts(updatedAmountsPayload)
        .pipe(
          tap(() => this.refreshUnsavedChanges('changedSegmentsAmounts', {})),
          catchError(error => this.onSaveRequestError(error, messages.updateSegmentAmountsFailure))
        )
    } else {
      return of(null)
    }
  }

  private saveUpdatedBudgetAllocations(): Observable<any> {
    const { allocationsLockState } = this.unsavedChanges.getValue();
    const amounts = this.budgetTableService.grandTotal.allocated;
    const payload = this.budgetAllocations.map(
      allocation => ({
        id: allocation.id,
        locked: allocationsLockState.hasOwnProperty(allocation.id)
          ? allocationsLockState[allocation.id]
          : allocation.locked,
        amount: amounts[allocation.id]
      })
    );
    return this.budgetAllocationService.updateListOfBudgetAllocations(payload)
      .pipe(
        tap(() => this.refreshUnsavedChanges('allocationsLockState', {})),
        catchError(error => this.onSaveRequestError(error, messages.updateBudgetAllocationsFailure))
      )
  }

  private saveUpdatedGroupsData(): Observable<any> {
    const { changedGroupsData } = this.unsavedChanges.getValue();
    const requests = this.budgetTableService.groupsList
      .filter(group => changedGroupsData[group.id])
      .map(group => this.segmentGroupService.updateSegmentGroup(group.id, { name: group.name, owner: group.owner }));

     return requests && requests.length ?
      forkJoin(requests).pipe(
        tap(() => this.refreshUnsavedChanges('changedGroupsData', {})),
        catchError(error => this.onSaveRequestError(error, messages.updateGroupsFailure))
      ) :
      of(null);
  }

  private removeEmptyGroups(): Observable<any> {
    const eliminatedGroupIds = Object.keys(this.savedGroupsById).filter(id => {
      const savedGroup: SegmentGroup = this.savedGroupsById[id];
      const existingGroup = this.budgetTableService.getGroupByKey(savedGroup.key);

      return !existingGroup;
    });
    const requests = eliminatedGroupIds.map(groupId => (
      this.segmentGroupService.deleteSegmentGroup(Number(groupId))
    ));

    if (requests?.length) {
      return forkJoin(requests).pipe(
        catchError((error) => this.onSaveRequestError(error, messages.deleteGroupsFailure))
      );
    }

    return of(null);
  }

  private refreshCreatedSegmentsCount() {
    const createdSegmentsCount = this.budgetTableService.segmentsList.filter(segment => !segment.id).length;
    this.refreshUnsavedChanges('createdSegmentsCount', createdSegmentsCount);
  }

  private refreshCreatedGroupsCount() {
    const createdGroupsCount = this.budgetTableService.groupsList.filter(group => !group.id).length;
    this.refreshUnsavedChanges('createdGroupsCount', createdGroupsCount);
  }

  private refreshUnsavedChanges(key: string, value: any) {
    this.unsavedChanges.next({
      ...this.unsavedChanges.getValue(),
      [key]: value
    })
  }

  private openIncreaseBudgetAmountDialog(increaseHandler: Function, cancelHandler: Function) {
    const dialogContextData: IncreaseBudgetAmountDialogContext = {
      title: 'Grand Total > Budget Amount',
      currency: this.budgetCurrency,
      allocatedTotal: this.budgetTableService.grandTotal?.total,
      budgetTotal: this.budget?.amount,
      remainingBudget: this.budgetTableService.remainingBudget,
      actions: [
        {
          label: 'Keep editing',
          type: DIALOG_ACTION_TYPE.DEFAULT,
          handler: () => cancelHandler(),
        },
        {
          label: 'Increase',
          type: DIALOG_ACTION_TYPE.FLAT,
          handler: () => increaseHandler(),
        }
      ],
    };
    const dialogConfig: MatDialogConfig = {
      ...increaseBudgetAmountDialogConfig,
      data: dialogContextData
    };
    const dialogRef = this.matDialog.open(IncreaseBudgetAmountDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(actionFired => {
      if (!actionFired) {
        cancelHandler();
      }
    });
  }

  private getBudgetSettingsModalConfig(isEditMode = false): MatDialogConfig<BudgetEditDialogData> {
    const budgetData = isEditMode ? (this.budget && this.mapBudgetDataToFormData(this.budget)) : null;
    const data: BudgetEditDialogData = {
      accountOwnerId: this.accountOwner.id,
      budgetData: budgetData,
      owners: this.ownersList
        .getValue()
        .filter(owner => owner.isAdmin && owner.status === CompanyUserStatus.Active)
        .map(owner => ({ id: owner.id, title: owner.name })),
      companyCurrency: this.company?.currency,
      isPlanfulUser: this.isPlanfulUser,
      isDefaultCEGCompany: this.budgetDataService.isCompanyWithDefaultNewCEGStructure
    };
    return { data };
  };

  public increaseBudgetAmount(remainingBudget: number): Observable<boolean> {
    if (remainingBudget >= 0) {
      return of(false);
    }

    this.utilityService.showLoading(false);

    return new Observable((subscriber) => {
      const increaseHandler = () => {
        this.utilityService.showLoading(true);
        this.updateBudget(
          this.budget.id,
          { amount: this.budget.amount + Math.abs(remainingBudget) }
        ).subscribe(
          () => {
            subscriber.next(true);
            subscriber.complete();
          },
          (error) => {
            this.utilityService.handleError(error);
            subscriber.next(false);
            subscriber.complete();
          }
        );
      };

      const cancelHandler = () => {
        subscriber.next(false);
        subscriber.complete();
      };

      this.openIncreaseBudgetAmountDialog(increaseHandler, cancelHandler);
    });
  }

  public editBudget() {
    this.openBudgetSettingsModal(true);
  }

  public openBudgetSettingsModal(isEditMode = false) {
    const dialogOperationName = isEditMode ? 'edit your budget settings' : 'create a new budget';

    this.canPerformBudgetInstanceOperation(dialogOperationName)
      .pipe(filter(allowed => allowed))
      .subscribe(
        () => {
          const dialogConfig = this.getBudgetSettingsModalConfig(isEditMode);
          this.matDialog.open(BudgetEditModalComponent, dialogConfig)
            .afterClosed()
            .subscribe(budget => {
              if (!budget && !isEditMode && this.budgetsCount === 0) {
                // If we cancel budget creation and there are no budgets - return to home page
                this.utilityService.showLoading(true);
                return this.router.navigate([ this.configuration.ROUTING_CONSTANTS.HOME ]);
              }
              if (budget) {
                const budgetDataFormatted = this.mapFormDataToBudgetData(budget);
                budgetDataFormatted.budget_to = getBudgetToDateString(budget.startDate);
                if (isEditMode) {
                  budgetDataFormatted.id = this.budget.id;
                  this.updateBudgetData(budgetDataFormatted);
                } else {
                  this.saveCreatedBudget(budgetDataFormatted);
                }
              }
            })
        }
      );
  }

  private confirmAction(action: ActionToConfirm, confirmCb: () => void) {
    const dialogData: DialogContext = utils.getConfirmationDialogData(action, this.budget.name);

    dialogData.actions = [
      {
        ...dialogData.cancelAction,
      },
      {
        ...dialogData.submitAction,
        handler: () => confirmCb()
      },
    ];

    this.dialogManager.openConfirmationDialog(dialogData, { width: dialogData.width })
  }

  public duplicateBudget() {
    this.canPerformBudgetInstanceOperation('duplicate your budget')
      .pipe(filter(allowed => allowed))
      .subscribe(
        () => {
          this.openDuplicateBudgetSpecificationDialog(
            ActionToConfirm.DuplicateBudgetSpecification,
            (data: { [key: string]: string | boolean} ) => {
            this.utilityService.showLoading(true);
            const budgetId = this.budget.id;
            this.budgetService.cloneBudgetSpecification(budgetId, data)
              .pipe(takeUntil(this.destroy$))
              .subscribe(
                (res) => {
                  this.budgetDataService.loadCompanyBudgetList(this.company?.id, (error) => this.utilityService.handleError(error));
                  this.budgetDataService.companyBudgetList$
                    .pipe(takeUntil(this.destroy$))
                    .subscribe(() => {
                      this.budgetDataService.selectBudget(Number(res.id));
                      this.budgetDataService.selectedBudgetStorage.remove(budgetId, Number(res.id));
                    });
                  this.utilityService.showToast({ Title: '', Message: messages.duplicateBudgetSuccess, Type: 'success' });
                },
                (error) => this.utilityService.handleError(error),
                () => this.utilityService.showLoading(false)
              )
          })
        }
      );
  }

  public duplicateSelectedSegments() {
    this.duplicateSegments(this.selectedRecords);
  }

  public duplicateSegments(selectedRecords: BudgetTableSelectedRecords) {
    this.budgetTableService.duplicateSegments(selectedRecords.segments);
    this.refreshCreatedSegmentsCount();
  }

  private openUnsavedChangesDialog(config: {
    cancelLabel: string;
    dialogContent: string;
    dialogTitle: string;
    proceedOnCancel: boolean;
    submitLabel?: string;
  }): Observable<boolean> {
    if (!this.hasUnsavedChanges) {
      return of(true);
    }

    const {
      cancelLabel,
      dialogContent,
      dialogTitle,
      proceedOnCancel,
      submitLabel = 'Save & Go',
    } = config;
    const canProceed = new Subject<boolean>();
    const cancelAction: DialogAction = {
      label: cancelLabel,
      type: DIALOG_ACTION_TYPE.DEFAULT,
      handler: () => {
        if (proceedOnCancel) {
          this.undoChanges();
        }
        canProceed.next(proceedOnCancel);
        canProceed.complete();
      },
    };
    const submitAction: DialogAction = {
      label: submitLabel,
      type: DIALOG_ACTION_TYPE.FLAT,
      handler: () => {
        if (!this.budgetTableValidationService.isFormDataValid()) {
          canProceed.next(false);
          canProceed.complete();
          return;
        }

        this.saveChanges({
          onSuccess: () => {
            canProceed.next(true);
            canProceed.complete();
          },
          onError: () => {
            canProceed.next(false);
            canProceed.complete();
          }
        });
      },
    };
    const dialogData: DialogContext = {
      title: dialogTitle,
      content: dialogContent,
      actions: [
        cancelAction,
        submitAction,
      ]
    };

    this.dialogManager.openConfirmationDialog(dialogData, { width: '380px' });

    return canProceed;
  }

  public canPerformBudgetInstanceOperation(operationName: string): Observable<boolean> {
    return this.openUnsavedChangesDialog({
      cancelLabel: 'Cancel',
      dialogTitle: 'Save your updated budget',
      dialogContent: `You need to save your budget changes before you can ${operationName}.`,
      proceedOnCancel: false
    })
  }

  public canLeavePage(): Observable<boolean> {
    return this.openUnsavedChangesDialog({
      cancelLabel: 'Discard',
      dialogTitle: 'You have unsaved changes',
      dialogContent: 'Do you want to save these changes before you go?',
      proceedOnCancel: true
    });
  }

  private checkSegmentsDataDiff = (updatedSegment: BudgetSegment, receivedSegment: BudgetSegment): boolean => {
    const { amounts: updatedSegmentAmounts, ...updatedSegmentData } = updatedSegment;
    const { amounts: receivedSegmentAmounts, ...receivedSegmentData } = receivedSegment;
    return !!getDiff(updatedSegmentData, receivedSegmentData).length;
  };

  private checkGroupsDataDiff = (updatedGroup: SegmentGroup, receivedGroup: SegmentGroup): boolean => {
    const { ...updatedGroupData } = updatedGroup;
    const { ...receivedGroupData } = receivedGroup;

    return !!getDiff(updatedGroupData, receivedGroupData).length;
  };

  private adaptBudgetSegments = (segments: BudgetSegmentDO[]): BudgetSegment[] => {
    return segments.map(
      segment => {
        const { projected_amount, amounts, segment_group, ...segmentData } = segment;
        return {
          ...segmentData,
          key: generateGUID(),
          projectedAmount: projected_amount,
          segmentGroup: segment_group,
          segmentGroupKey: null,
          amounts: this.adaptSegmentsAmounts(amounts)
        }
      }
    )
  };

  private processSegmentGroups(groups: SegmentGroup[] = []) {
    this.budgetTableService.setGroupsList(groups);
    this.tableDataLoadingState.groups = true;
    this.initTableState();
  };

  private adaptSegmentsAmounts = (amounts: BudgetSegmentAmountDO[]): BudgetSegmentAmount[] => {
    return amounts.map(segmentAmount => ({
      id: segmentAmount.id,
      budgetAllocationId: segmentAmount.company_budget_alloc,
      amount: segmentAmount.amount,
      segmentId: segmentAmount.company_budget_segment1,
      budget: segmentAmount.budget,
      forecastAmount: segmentAmount?.forecast_amount,
    }))
  };

  private createOwnerSelectOptions = (owner: UserOwner, budgetId: number, segmentId?: number): OwnerSelectOption => {
    const available = owner.isAdmin || budgetId && segmentId && owner.permissions.some(
      (permission: BudgetPermission) =>
        permission.budget.id === budgetId && permission.segments.some(
          segment => segment.id === segmentId
        )
    );
    return {
      id: owner.id,
      name: owner.name,
      initials: owner.initials,
      hidden: !available,
      status: owner.status
    };
  };

  private createOwnersList = (usersProfiles: UserProfileDO[], companyUsers: CompanyUserDO[]): UserOwner[] => {
    const usersProfilesById = createUsersProfilesMap(usersProfiles);
    return companyUsers.map(
      (companyUser: CompanyUserDO) => {
        const userProfile: UserProfileDO = usersProfilesById[companyUser.user];
        const permissions = companyUser.permissions.map(budgetPermission => adaptBudgetPermission(budgetPermission, companyUser.is_admin));
        const fullName = `${userProfile.first_name} ${userProfile.last_name}`;

        return {
          id: userProfile.user,
          companyUserId: companyUser.id,
          name: fullName,
          initials: getInitialsFromName(fullName),
          permissions: permissions,
          isAdmin: companyUser.is_admin,
          status: companyUser.status
        }
      }
    ).sort((a, b) => a.name.localeCompare(b.name));
  };

  private mapBudgetDataToFormData = (data: Budget): BudgetEditDialogFormData => {
    return {
      name: data.name,
      owner: data.owner,
      amount: data.amount || 0,
      type: data.type,
      startDate: parseDateString(data.budget_from),
      cegStatusesEnabled: data?.new_campaigns_programs_structure,
      forecastEnabled: data?.is_forecast_enabled,
    }
  };

  private mapFormDataToBudgetData = (formData: BudgetEditDialogFormData): Partial<Budget> => {
    const { startDate, cegStatusesEnabled, forecastEnabled, ...budgetData } = formData;
    return {
      ...budgetData,
      budget_from: createDateString(formData.startDate),
      new_campaigns_programs_structure: formData.cegStatusesEnabled,
      is_forecast_enabled: formData.forecastEnabled,
    }
  };

  private removeBudgetIntegrations(budgetId: number, [integrations, campaignIds]: [MetricIntegrations, number[] | null]): Observable<any> {
    const integrationEntries =
      (Object.entries(integrations) as [MetricIntegrationName, Integration[]][])
        .filter(([, integrationItems]) => integrationItems.length > 0);

    return integrationEntries.length ?
      forkJoin(integrationEntries.map(([integrationName]) => this.removeBudgetIntegration(integrationName, this.company.id, budgetId, campaignIds))) :
      of([]);
  }

  private removeBudgetIntegration(
    integrationName: MetricIntegrationName,
    companyId: number,
    budgetId: number,
    campaignIds: number[]
  ): Observable<any> {
    const provider = this.metricIntegrationsProvider.metricIntegrationProviderByType(integrationName);
    return AdsIntegrations.includes(integrationName) ?
      (provider as MetricsProviderWithImplicitMappingsDataService).removeIntegrationsForBudget(companyId, budgetId) :
      campaignIds?.length ? provider.deleteCampaignsMappings(companyId, campaignIds) : of(null);
  }

  openDuplicateIntegrationsDialog = (dialogAction: ActionToConfirm, confirmCb: (confirm: boolean) => void) => {
    const dialogData: DialogContext = utils.getConfirmationDialogData(dialogAction, this.budget.name);

    dialogData.actions = [
      {
        ...dialogData.cancelAction
      },
      {
        ...dialogData.submitAction,
        handler: () => confirmCb(true)
      }
    ];
    return this.dialogManager.openConfirmationDialog(dialogData, { width: dialogData.width });
  }

  openDuplicateBudgetSpecificationDialog = (dialogAction: ActionToConfirm, confirmCb: (data) => void) => {
    const dialogData: Partial<DuplicateBudgetContext> = utils.getConfirmationDialogData(dialogAction, this.budget.name);
    dialogData.context = this.budgetDataSpecification;

    dialogData.actions = [
      {
        ...dialogData.cancelAction,
      },
      {
        ...dialogData.submitAction,
        handler: (data: { [key: string]: string | boolean}) => {
          this.openDuplicateIntegrationsDialog(ActionToConfirm.DuplicateBudgetIntegrations, (response) => {
            if (response) {
              confirmCb(data);
            }
          });
        }
      },
    ];
    this.dialogManager.openDuplicateBudgetSpecificationDialog(dialogData, { width: dialogData.width });

  }

  public todayDateToggleState(state: boolean): void {
    const newDate = state ? this._todayFixedDate : null;
    this.updateTodayFixedDate(newDate);
  }

  public updateTodayFixedDate(newDate: Date): void {
    const dateValue = newDate ? createDateString(newDate) : null;
    this.utilityService.showLoading(true);
    this.updateBudget(this.budget.id, { fixed_date: dateValue })
      .pipe(
        finalize(() => this.utilityService.forceHideLoading()),
        takeUntil(this.destroy$)
      ).subscribe(
      () => {
        this._fixedDateEnabled = !!newDate;
        this._todayFixedDate = newDate || new Date();
        this.utilityService.showCustomToastr('Today\'s fixed date was updated successfully');
      },
      () => this.utilityService.handleError({ message: 'Failed to update today\'s fixed date' }),
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
