import { Component, ViewChild, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { UtilityService } from 'app/shared/services/utility.service';
import { Configuration } from 'app/app.constants';
import { Validations } from 'app/app.validations';
import { GoogleTagManagerService } from 'app/shared/services/google-tag-manager.service';
import { UserManager } from 'app/user/services/user-manager.service';
import { AppInit } from 'app/app-init.service';
import { ChangePasswordMode } from '../../types/change-password-mode.enum';
import { PerfService } from 'app/web-workers/perf/perf.service';
import { BudgetObjectDialogService } from '@shared/services/budget-object-dialog.service';
import { Observable } from 'rxjs';
import { LoginStrings } from './login.strings';
import { PostAuthService } from './login.service';

const NEW_PASSWORD_CHALLENGE_NAME = 'NEW_PASSWORD_REQUIRED';
const INVALID_CREDENTIALS_MESSAGE = 'Invalid email or password entered.';

@Component({
  selector: 'app-root',
  templateUrl: './login.component.html',
  providers: [PostAuthService]
})
export class LoginComponent implements OnDestroy {
  @ViewChild('form', { static: true }) FormData: NgForm;

  subscriptions: Subscription[] = [];
  form: any;
  errorMessagesByCode = {
    'UserNotFoundException': INVALID_CREDENTIALS_MESSAGE,
    'NotAuthorizedException': INVALID_CREDENTIALS_MESSAGE,
  };

  isPasswordHidden = true;

  constructor(
    private readonly configuration: Configuration,
    public readonly utilityService: UtilityService,
    public readonly router: Router,
    public readonly validations: Validations,
    private readonly gtmService: GoogleTagManagerService,
    public readonly userManager: UserManager,
    private readonly route: ActivatedRoute,
    private readonly appInit: AppInit,
    private readonly perfService: PerfService,
    private readonly dialogManager: BudgetObjectDialogService,
    private readonly postAuthService: PostAuthService
  ) {
    this.form = {
      email: '',
      password: ''
    };
  }

  ngOnInit(): void {
    this.utilityService.forceHideLoading();
  }

  public login(form, isValid: boolean): void {
    this.utilityService.showLoading(true);
    if (isValid) {
      this.subscriptions.push(
        this.userManager.isLoggedIn()
          .pipe(
            tap(loggedIn => loggedIn && this.router.navigate([this.configuration.DEFAULT_ROUTE])),
            filter(loggedIn => !loggedIn),
            switchMap(() => this.userManager.login(form.email.toLowerCase(), form.password)),
            tap(user =>
              user.challengeName === NEW_PASSWORD_CHALLENGE_NAME &&
              this.userManager.navigateToChangePassword({
                email: form.email.toLowerCase(),
                oldPassword: form.password,
                successRoutePath: this.configuration.ROUTING_CONSTANTS.QUICK_START,
                mode: ChangePasswordMode.CompleteNewPassword,
              })
            ),
            filter(user => user.challengeName !== NEW_PASSWORD_CHALLENGE_NAME),
            switchMap(() => this.postAuthService.isUserAllowedToProceed()),
            tap((isUserAllowed) => {
              if (!isUserAllowed) {
                this.userManager.invalidateSession();
                this.subscriptions.push(
                  this.blockInactiveOrgUserLoginDialog().subscribe()
                );
              }
            }),
            filter((isUserAllowed: boolean) => isUserAllowed),
          )
          .subscribe(
            () => { this.onLoggedIn() },
            error => this.onError(error)
          )
      );
    }
  }

  togglePasswordVisibility(event: MouseEvent): void {
    event.stopPropagation();
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  private onLoggedIn(): void {
    this.userManager.currentUserId$.subscribe(userId => {
      this.gtmService.sendUserId(userId);
      this.appInit.resetLastNavigationRoutesRecognizedEvent();
      this.userManager.setLoggedIn();
      this.handleLoginRouting();
      this.utilityService.showLoading(false);
      this.perfService.startTrackEvent(this.perfService.componentsToTrack.login);
    });
  }

  private onError(error): void {
    const { code } = error;
    let message = this.errorMessagesByCode[code] || error.message;
    if (error.message === 'Password attempts exceeded') {
      message = 'Too many failed log in attempts, please try again in 15 minutes';
    }
    this.utilityService.handleError({ message });
  }

  private handleLoginRouting(): Promise<boolean> {
    const returnUrl = this.route.snapshot.queryParams[this.configuration.QUERY_PARAMS.RETURN_URL];
    if (returnUrl) {
      return this.router.navigateByUrl(returnUrl);
    }
    return this.router.navigate([this.configuration.DEFAULT_ROUTE]);
  }

  private blockInactiveOrgUserLoginDialog() {
    this.utilityService.showLoading(false);
    return new Observable((subscriber) => {
      const dialogRef = this.dialogManager.openConfirmationDialog(
        {
          title: LoginStrings.dialogs?.inactiveCompanyUserLogin.title,
          submitAction: {
            label: 'OK',
            handler: () => subscriber.unsubscribe()
          },
          content: LoginStrings.dialogs?.inactiveCompanyUserLogin.content
        },
        {
          width: '480px',
          panelClass: 'extended-confirmation-dialog'
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result === undefined || result === null) {
          subscriber.unsubscribe();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
